import type * as QP from "shared-lib/query-product";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { UnitDivide, Units } from "uom-units";
import type { FanAirResult, Curve, SpeedControl, WorkingPoint } from "../result-items-types";
import * as IP from "./interpolation";
import * as Utils from "../shared/utils";
import { calculateKFactor, calculateYFromKfactor } from "./k-factor";
import * as AirDensity from "../shared/air-density";
import { getSolutionValue } from "./solution";
import { calculateShaftPowerFromWorkingPoint } from "./shaft-power";
import { createDataPointCurves } from "./curve";

const epsilonOutsideArea = 0.2;
export function calculate(
  speedControl: SpeedControl,
  airDataRows: ReadonlyArray<QP.AirData>,
  airLimitsData: ReadonlyArray<QP.AirLimitsData>,
  desiredAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  desiredExternalPressure: Amount.Amount<Quantity.Pressure> | undefined,
  accessoryPressureCurves: ReadonlyArray<Curve>,
  airDensity: Amount.Amount<Quantity.Density> | undefined,
  fixedCurveId: string | undefined,
  ignoreWorkLimits: boolean,
  ignoreOutsideValidArea: boolean
): FanAirResult {
  // Any 0 is set a small number to prevent division by zero errors
  const airData = airDataRows.reduce<Array<QP.AirData>>((sofar, point) => {
    const flow = point.flow === 0 ? 0.000000000000000000001 : point.flow;
    const value = point.value === 0 ? 0.000000000000000000001 : point.value;
    sofar.push({ ...point, flow, value });
    return sofar;
  }, []);

  const pressureData = airData.filter((p) => p.param === "Ps_v");

  const pressureCurves = createDataPointCurves(
    Units.LiterPerSecond,
    Units.Pascal,
    pressureData,
    ignoreWorkLimits ? [] : airLimitsData
  );

  const adjustedPressureCurves = applyAccessoryPressureDrops(pressureCurves, accessoryPressureCurves);

  const powerCurves = createDataPointCurves(
    Units.LiterPerSecond,
    Units.Watt,
    airData.filter((p) => p.param === "P_v"),
    []
  );
  const currentCurves = createDataPointCurves(
    Units.LiterPerSecond,
    Units.Ampere,
    airData.filter((p) => p.param === "I_v"),
    []
  );

  const rpmCurves = createDataPointCurves(
    Units.LiterPerSecond,
    Units.RevolutionsPerMinute,
    airData.filter((p) => p.param === "r_v"),
    []
  );

  const shaftPowerCurves = createDataPointCurves(
    Units.LiterPerSecond,
    Units.Watt,
    airData.filter((p) => p.param === "H_v"),
    []
  );

  let diff: number | undefined = undefined;
  let distanceWorkMax: number | undefined = undefined;
  let distanceWorkDesired: number | undefined = undefined;
  let desiredOutsideValidArea: boolean | undefined = undefined;
  let airFlowLps: number | undefined = undefined;
  let externalPressurePa: number | undefined = undefined;
  let controlVoltageV: number | undefined = undefined;
  let controlVoltageP: number | undefined = undefined;

  let frequencyControlH: number | undefined = undefined;
  let frequencyControlP: number | undefined = undefined;

  let supplyVoltageV: number | undefined = undefined;
  let supplyVoltageP: number | undefined = undefined;
  let powerW: number | undefined = undefined;
  let currentI: number | undefined = undefined;
  let fanSpeedRpm: number | undefined = undefined;
  let sfpKwPerCmps: number | undefined = undefined;
  let efficiencyP: number | undefined = undefined;
  let efficiencyShaftPowerP: number | undefined = undefined;
  let voltageLowV: number | undefined = undefined;
  let voltageHighV: number | undefined = undefined;
  let voltageLowP: number | undefined = undefined;
  let voltageHighP: number | undefined = undefined;
  let fanSpeedLowRpm: number | undefined = undefined;
  let fanSpeedHighRpm: number | undefined = undefined;
  let airflowEfficiency: number | undefined = undefined;
  let shaftPowerW: number | undefined = undefined;

  const desiredAirFlowLps = desiredAirFlow && Amount.valueAs(Units.LiterPerSecond, desiredAirFlow);

  const requiredPressureByDensity = AirDensity.calculateExternalPressureWithDensity(
    desiredExternalPressure,
    airDensity
  );
  const desiredExternalPressurePa = desiredExternalPressure && Amount.valueAs(Units.Pascal, desiredExternalPressure);
  const requiredPressureByDensityPa =
    requiredPressureByDensity && Amount.valueAs(Units.Pascal, requiredPressureByDensity);

  const workingPoint =
    desiredAirFlowLps && requiredPressureByDensityPa
      ? findWorkingPoint(
          speedControl,
          desiredAirFlowLps,
          requiredPressureByDensityPa,
          adjustedPressureCurves,
          fixedCurveId
        )
      : undefined;

  const validWorkingInterval =
    desiredAirFlowLps && requiredPressureByDensityPa
      ? findValidSystemCurveInterval(
          speedControl,
          desiredAirFlowLps,
          requiredPressureByDensityPa,
          adjustedPressureCurves
        )
      : undefined;

  const flows = airData.map((p) => p.flow);
  const pressures = pressureData.map((p) => p.value);
  const minAirFlowLps = flows.length > 0 ? Math.min(...flows) : undefined;
  const maxAirFlowLps = flows.length > 0 ? Math.max(...flows) : undefined;
  const minPressurePa = flows.length > 0 ? Math.min(...pressures) : undefined;
  const maxPressurePa = flows.length > 0 ? Math.max(...pressures) : undefined;

  if (desiredAirFlowLps && desiredExternalPressurePa && workingPoint) {
    powerW = calcValueFromWorkingPoint(workingPoint, powerCurves);
    currentI = calcValueFromWorkingPoint(workingPoint, currentCurves);
    fanSpeedRpm = calcValueFromWorkingPoint(workingPoint, rpmCurves);
    shaftPowerW = calculateShaftPowerFromWorkingPoint(workingPoint, shaftPowerCurves, rpmCurves, fanSpeedRpm);
    const maxControlVoltage = Math.max(...pressureCurves.map((c) => c.controlVoltage), 10);
    const maxSupplyVoltage = Math.max(...pressureCurves.map((c) => c.supplyVoltage), 10);
    const maxFrequency = Math.max(...pressureCurves.map((c) => c.controlFrequency), 10);
    airFlowLps = workingPoint.point.x;
    externalPressurePa = workingPoint.point.y;
    controlVoltageV = workingPoint.controlVoltage;
    controlVoltageP = (100 * workingPoint.controlVoltage) / maxControlVoltage;
    supplyVoltageV = workingPoint.supplyVoltage;
    supplyVoltageP = (100 * workingPoint.supplyVoltage) / maxSupplyVoltage;

    frequencyControlH = workingPoint.controlFrequency;
    frequencyControlP = (100 * workingPoint.controlFrequency) / maxFrequency;

    fanSpeedRpm = calcValueFromWorkingPoint(workingPoint, rpmCurves);
    const airFlowCmps = Utils.convertFromTo(airFlowLps, Units.LiterPerSecond, Units.CubicMeterPerSecond);
    sfpKwPerCmps = powerW && (0.001 * powerW) / airFlowCmps;
    efficiencyP = powerW && (100 * externalPressurePa * airFlowCmps) / powerW;
    efficiencyShaftPowerP = shaftPowerW && (100 * externalPressurePa * airFlowCmps) / shaftPowerW;
    const airFlowLowLps = 0.6 * airFlowLps;
    const k = calculateKFactor(desiredAirFlowLps, desiredExternalPressurePa);
    const pressureLowPa = calculateYFromKfactor(k, airFlowLowLps);
    const workingPointLow = findWorkingPoint(speedControl, airFlowLowLps, pressureLowPa, adjustedPressureCurves);

    const airflowCubicFeetPerMinute = Utils.convertFromTo(airFlowLps, Units.LiterPerSecond, Units.CubicFeetPerMinute);
    airflowEfficiency = powerW && airflowCubicFeetPerMinute / powerW;

    voltageLowV = workingPointLow?.controlVoltage;
    voltageLowP = workingPointLow && (100 * workingPointLow.controlVoltage) / maxControlVoltage;
    fanSpeedLowRpm = workingPointLow && calcValueFromWorkingPoint(workingPointLow, rpmCurves);

    const maxCurve =
      adjustedPressureCurves.length > 0 ? adjustedPressureCurves[adjustedPressureCurves.length - 1] : undefined;
    const maxPoint = maxCurve && IP.findSystemCurveIntersection(k, maxCurve.spline);
    const minCurve = adjustedPressureCurves[0];
    const minPoint = minCurve && IP.findSystemCurveIntersection(k, minCurve.spline);
    const airFlowHighLps = maxPoint && 0.5 * (maxPoint.x + airFlowLps);
    const pressureHighPa = airFlowHighLps !== undefined ? calculateYFromKfactor(k, airFlowHighLps) : undefined;
    const workingPointHigh =
      airFlowHighLps !== undefined &&
      pressureHighPa !== undefined &&
      findWorkingPoint(speedControl, airFlowHighLps, pressureHighPa, adjustedPressureCurves);
    voltageHighV = workingPointHigh ? workingPointHigh.controlVoltage : undefined;
    voltageHighP = workingPointHigh ? (100 * workingPointHigh.controlVoltage) / maxControlVoltage : undefined;
    fanSpeedHighRpm = workingPointHigh ? calcValueFromWorkingPoint(workingPointHigh, rpmCurves) : undefined;
    // Euclidean distance between the working point and the maximum workingpoint on the systemcurve
    distanceWorkMax =
      maxPoint &&
      // eslint-disable-next-line no-restricted-properties
      Math.sqrt(Math.pow(maxPoint.x - (airFlowLps ?? 0), 2) + Math.pow(maxPoint.y - (externalPressurePa ?? 0), 2));

    // Euclidean distance between the working point and the desired working point (inputs)
    distanceWorkDesired = Math.sqrt(
      // eslint-disable-next-line no-restricted-properties
      Math.pow(desiredAirFlowLps - (airFlowLps ?? 0), 2) +
        // eslint-disable-next-line no-restricted-properties
        Math.pow(desiredExternalPressurePa - (externalPressurePa ?? 0), 2)
    );
    diff = (100.0 * (airFlowLps ?? 0 - desiredAirFlowLps)) / desiredAirFlowLps - 100.0;

    // Determine if the desired point is withing the valid diagram area
    const belowValidArea = minPoint ? desiredAirFlowLps < minPoint.x - epsilonOutsideArea : undefined;
    const aboveValidArea = maxPoint ? desiredAirFlowLps > maxPoint.x + epsilonOutsideArea : undefined;
    desiredOutsideValidArea =
      !!aboveValidArea || (belowValidArea && adjustedPressureCurves.length > 1 && speedControl !== "None");
  }

  return {
    pressureCurves,
    adjustedPressureCurves,
    powerCurves,
    feiCurves: [],
    shaftPowerCurves,
    currentCurves,
    rpmCurves,
    speedControl,
    minAirFlow: Utils.maybeAmount(minAirFlowLps, Units.LiterPerSecond, 1),
    maxAirFlow: Utils.maybeAmount(maxAirFlowLps, Units.LiterPerSecond, 1),
    minPressure: Utils.maybeAmount(minPressurePa, Units.Pascal, 1),
    maxPressure: Utils.maybeAmount(maxPressurePa, Units.Pascal, 1),

    airDensity: airDensity,

    workingPoint: workingPoint,
    systemCurveMinValidPoint: validWorkingInterval?.min && {
      airFlow: Amount.create(validWorkingInterval.min.x, Units.LiterPerSecond, 1),
      pressure: Amount.create(validWorkingInterval.min.y, Units.Pascal, 1),
    },
    systemCurveMaxValidPoint: validWorkingInterval?.max && {
      airFlow: Amount.create(validWorkingInterval.max.x, Units.LiterPerSecond, 1),
      pressure: Amount.create(validWorkingInterval.max.y, Units.Pascal, 1),
    },
    diff: Utils.maybeAmount(diff, Units.Percent, 2),
    distanceWorkingPointToDesiredPoint: distanceWorkDesired,
    distanceWorkingPointToMaxPoint: distanceWorkMax,
    desiredPointIsOutsideValidArea: ignoreOutsideValidArea ? false : desiredOutsideValidArea,
    shaftPower: Utils.maybeAmount(shaftPowerW, Units.Watt, 1),
    desiredAirFlow: Utils.maybeAmount(desiredAirFlowLps, Units.LiterPerSecond, 0),
    desiredExternalPressure: Utils.maybeAmount(desiredExternalPressurePa, Units.Pascal, 1),
    airFlow: Utils.maybeAmount(airFlowLps, Units.LiterPerSecond, 0),
    externalPressure: Utils.maybeAmount(externalPressurePa, Units.Pascal, 1),
    controlVoltage: controlVoltageV !== 0 ? Utils.maybeAmount(controlVoltageV, Units.Volt, 0) : undefined,
    controlVoltagePercent: controlVoltageP !== 0 ? Utils.maybeAmount(controlVoltageP, Units.Percent, 0) : undefined,

    frequencyControlHertz:
      frequencyControlH !== 0 && speedControl === "Frequency converter"
        ? Utils.maybeAmount(frequencyControlH, Units.Hertz, 0)
        : undefined,
    frequencyControlPercent:
      frequencyControlP !== 0 && speedControl === "Frequency converter"
        ? Utils.maybeAmount(frequencyControlP, Units.Percent, 0)
        : undefined,

    supplyVoltage: supplyVoltageV !== 0 ? Utils.maybeAmount(supplyVoltageV, Units.Volt, 0) : undefined,
    supplyVoltagePercent: supplyVoltageP !== 0 ? Utils.maybeAmount(supplyVoltageP, Units.Percent, 0) : undefined,
    power: Utils.maybeAmount(powerW, Units.Watt, 1),
    current: Utils.maybeAmount(currentI, Units.Ampere, 3),
    fanSpeed: Utils.maybeAmount(fanSpeedRpm, Units.RevolutionsPerMinute, 0),
    sfp: Utils.maybeAmount(sfpKwPerCmps, Units.KiloWattPerCubicMeterPerSecond, 2),
    airflowEfficiency: Utils.maybeAmount(
      airflowEfficiency,
      UnitDivide.volumeFlowByPower("CubicFeetPerMinutePerWatt", Units.CubicFeetPerMinute, Units.Watt),
      2
    ),
    efficiency: Utils.maybeAmount(efficiencyP, Units.Percent, 1),
    efficiencyShaftPower: Utils.maybeAmount(efficiencyShaftPowerP, Units.Percent, 1),
    voltageLow: Utils.maybeAmount(voltageLowV, Units.Volt, 0),
    voltageHigh: Utils.maybeAmount(voltageHighV, Units.Volt, 0),
    voltagePercentLow: Utils.maybeAmount(voltageLowP, Units.Percent, 0),
    voltagePercentHigh: Utils.maybeAmount(voltageHighP, Units.Percent, 0),
    fanSpeedLow: Utils.maybeAmount(fanSpeedLowRpm, Units.RevolutionsPerMinute, 0),
    fanSpeedHigh: Utils.maybeAmount(fanSpeedHighRpm, Units.RevolutionsPerMinute, 0),
    diagramDrawMethod: "AMCA",
  };
}

function applyAccessoryPressureDrops(
  pressureCurves: ReadonlyArray<Curve>,
  accessoryCurves: ReadonlyArray<Curve>
): ReadonlyArray<Curve> {
  return pressureCurves.map((c) => ({
    ...c,
    spline: accessoryCurves.reduce((a, b) => IP.splineSubtract(a, b.spline), c.spline),
  }));
}

export function findWorkingPoint(
  speedControl: SpeedControl,
  x: number,
  y: number,
  curves: ReadonlyArray<Curve>,
  fixedCurveId?: string
): WorkingPoint | undefined {
  if (curves.length === 0) {
    return undefined;
  }
  // Build system curve
  const k = calculateKFactor(x, y);

  // A working point is only valid if the system curves passas through all fan curves
  const nonIntersecting = curves.find((c) => {
    const int = IP.findSystemCurveIntersection(k, c.spline);
    if (!int) {
      return true;
    }
    if (int.x < c.workMin || int.x > c.workMax) {
      return true;
    }
    return false;
  });
  if (nonIntersecting) {
    return undefined;
  }

  switch (speedControl) {
    case "None":
      return findWorkingPointNoSpeedControl(k, curves);
    case "Stepless":
      return findWorkingPointSteplessSpeedControl(k, x, y, curves);
    case "Transformer":
      return findWorkingPointSteppedSpeedControl(k, x, curves, fixedCurveId);
    default:
      return undefined; // Unknown Speedcontrol
  }
}

export function findValidSystemCurveInterval(
  speedControl: SpeedControl,
  x: number,
  y: number,
  curves: ReadonlyArray<Curve>
):
  | {
      readonly min: IP.Vec2 | undefined;
      readonly max: IP.Vec2 | undefined;
    }
  | undefined {
  if (curves.length === 0) {
    return undefined;
  }

  const k = calculateKFactor(x, y);

  const minX = Math.min(...curves.map((c) => c.spline.xMin));
  const maxX = Math.max(...curves.map((c) => c.spline.xMax));

  if (speedControl === "None") {
    const wp = findWorkingPointNoSpeedControl(k, curves);
    if (!wp) {
      return undefined;
    }
    return {
      min: undefined,
      max: wp.point,
    };
  } else if (speedControl === "Transformer") {
    const min = findWorkingPointSteppedSpeedControl(k, minX, curves, undefined)?.point;
    const max = findWorkingPointSteppedSpeedControl(k, maxX, curves, undefined)?.point;
    if (!min || !max) {
      return undefined;
    }
    return {
      min: min,
      max: max,
    };
  } else if (speedControl === "Stepless" || speedControl === "Frequency converter") {
    const minY = k * minX * minX;
    const maxY = k * maxX * maxX;
    const min = findWorkingPointSteplessSpeedControl(k, minX, minY, curves)?.point;
    const max = findWorkingPointSteplessSpeedControl(k, maxX, maxY, curves)?.point;
    if (!min || !max) {
      return undefined;
    }
    return {
      min: min,
      max: max,
    };
  }
  return undefined;
}

function findWorkingPointSteppedSpeedControl(
  k: number,
  x: number,
  curves: ReadonlyArray<Curve>,
  fixedCurveId: string | undefined
): WorkingPoint | undefined {
  const fixedCurveIx = curves.findIndex((c) => c.id === fixedCurveId);

  // Fixed Curve
  if (fixedCurveIx >= 0) {
    const fixedCurve = curves[fixedCurveIx];

    const fixedPoint = IP.findSystemCurveIntersection(k, fixedCurve.spline);
    if (fixedPoint === undefined) {
      return undefined;
    }

    return {
      point: fixedPoint,
      controlVoltage: fixedCurve.controlVoltage,
      supplyVoltage: fixedCurve.supplyVoltage,
      controlFrequency: fixedCurve.controlFrequency,
      curveId: fixedCurve.id,
      curveIndex: fixedCurveIx,
      solution: {
        type: "Curve",
        curveId: fixedCurve.id,
        point: fixedPoint,
        ratio: 1,
      },
    };
  }

  // Find closest curve above input airflow and pressure
  const curvesWithPoint = curves.map((c, i) => ({
    curve: c,
    index: i,
    point: IP.findSystemCurveIntersection(k, c.spline),
  }));
  const { point, curve, index } =
    curvesWithPoint
      .filter((r) => !!r.point)
      .sort((a, b) => a.point!.x - b.point!.x)
      .find((r) => !!r.point && r.point.x > x) ?? curvesWithPoint[curvesWithPoint.length - 1];
  if (!point || point.x < curve.workMin || point.x > curve.workMax) {
    return undefined;
  }
  return {
    point: point,
    controlVoltage: curve.controlVoltage,
    supplyVoltage: curve.supplyVoltage,
    controlFrequency: curve.controlFrequency,
    curveId: curve.id,
    curveIndex: index,
    solution: {
      type: "Curve",
      curveId: curve.id,
      point: point,
      ratio: 1,
    },
  };
}

function findWorkingPointNoSpeedControl(k: number, curves: ReadonlyArray<Curve>): WorkingPoint | undefined {
  // Take the highest curve and set workingpoint to intersection between system curve and highest curve
  const maxCurve = curves[curves.length - 1];
  const point = IP.findSystemCurveIntersection(k, maxCurve.spline);
  if (!point || point.x < maxCurve.workMin || point.x > maxCurve.workMax) {
    return undefined;
  }
  return {
    point: point,
    controlVoltage: maxCurve.controlVoltage,
    supplyVoltage: maxCurve.supplyVoltage,
    controlFrequency: maxCurve.controlFrequency,
    curveId: maxCurve.id,
    curveIndex: curves.length - 1,
    solution: {
      type: "Curve",
      curveId: maxCurve.id,
      point: point,
      ratio: 1,
    },
  };
}

function findWorkingPointSteplessSpeedControl(
  k: number,
  x: number,
  y: number,
  curves: ReadonlyArray<Curve>
): WorkingPoint | undefined {
  // Find all points where the Systemcurve intersects the measured curve
  const interSections = curves
    .map((c) => {
      return {
        id: c.id,
        controlVoltage: c.controlVoltage,
        supplyVoltage: c.supplyVoltage,
        workMin: c.workMin,
        workMax: c.workMax,
        controlFrequency: c.controlFrequency,
        point: IP.findSystemCurveIntersection(k, c.spline),
      };
    })
    .sort((a, b) => (a.point?.y ?? 0) - (b.point?.y ?? 0));

  // // System curve must pass through all curves to be valid // Disablef for now as we get decimal/rounding issues for some fans
  // if (interSections.some((ic) => ic.point === undefined)) {
  //   return undefined;
  // }

  // Find points above and below the y input
  const higherCurve = interSections.find((ic) => ic.point !== undefined && ic.point.y > y);
  const lowerCurve = [...interSections.reverse()].find((ic) => ic.point !== undefined && ic.point.y <= y);

  if (!lowerCurve && !higherCurve) {
    // System curve does not pass through any curves close enough
    return undefined;
  }

  if (!higherCurve) {
    const point = lowerCurve!.point!;
    if (point.x < lowerCurve!.workMin || point.x > lowerCurve!.workMax) {
      return undefined;
    }
    // Input is higher the highest curve, use lowercurve intersection point as working point
    return {
      point: { x: lowerCurve!.point!.x, y: lowerCurve!.point!.y },
      controlVoltage: lowerCurve!.controlVoltage,
      supplyVoltage: lowerCurve!.supplyVoltage,
      controlFrequency: lowerCurve!.controlFrequency,
      curveId: lowerCurve!.id,
      solution: {
        type: "Curve",
        curveId: lowerCurve!.id,
        point: { x: lowerCurve!.point!.x, y: lowerCurve!.point!.y },
        ratio: 1,
      },
    };
  }

  if (!lowerCurve) {
    const point = higherCurve.point!;
    if (point.x < higherCurve.workMin || point.x > higherCurve.workMax) {
      return undefined;
    }
    // Input is lower than the lowest curve, use highercurve intersection point as working point
    return {
      point: { x: higherCurve.point!.x, y: higherCurve.point!.y },
      controlVoltage: higherCurve.controlVoltage,
      supplyVoltage: higherCurve.supplyVoltage,
      controlFrequency: higherCurve.controlFrequency,
      curveId: higherCurve!.id,
      solution: {
        type: "Curve",
        curveId: higherCurve.id,
        point: { x: higherCurve.point!.x, y: higherCurve.point!.y },
        ratio: 1,
      },
    };
  }

  // input is between two curves
  // Check that we aren not inside a "red area"
  const pointHigh = higherCurve.point!;
  if (pointHigh.x < higherCurve.workMin || pointHigh.x > higherCurve.workMax) {
    return undefined;
  }

  const pointLow = lowerCurve.point!;
  if (pointLow.x < lowerCurve.workMin || pointLow.x > lowerCurve.workMax) {
    return undefined;
  }

  // Interpolate between the two curves
  const ratio = (x - lowerCurve.point!.x) / (higherCurve.point!.x - lowerCurve.point!.x);
  const workingPoint = IP.vec2Create(x, y);

  return {
    point: workingPoint,
    controlVoltage: (higherCurve.controlVoltage - lowerCurve.controlVoltage) * ratio + lowerCurve.controlVoltage,
    supplyVoltage: (higherCurve.supplyVoltage - lowerCurve.supplyVoltage) * ratio + lowerCurve.supplyVoltage,
    controlFrequency:
      (higherCurve.controlFrequency - lowerCurve.controlFrequency) * ratio + lowerCurve.controlFrequency,
    solution: {
      type: "Interpolated",
      lower: {
        type: "Curve",
        curveId: lowerCurve.id,
        point: { x: lowerCurve.point!.x, y: lowerCurve.point!.y },
        ratio: 1,
      },
      higher: {
        type: "Curve",
        curveId: higherCurve.id,
        point: { x: higherCurve.point!.x, y: higherCurve.point!.y },
        ratio: 1,
      },
      point: workingPoint,
      ratio: ratio,
    },
  };
}

export function calcValueFromWorkingPoint(
  workingPoint: WorkingPoint,
  curves: ReadonlyArray<Curve>
): number | undefined {
  return getSolutionValue(curves, workingPoint.solution);
}
