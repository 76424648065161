export * from "./functions";
export * from "./query-types";
export * from "./table-types";
export { ProductCacheState } from "./cache/types";
export {
  PropertyTable,
  LanguageTable,
  CodeTable,
  ProductProperty,
  Translation,
  ProductPropertyDefValue,
  ProductPropertyValue,
  ProductCode,
  ProductImage,
  ProductDocument,
  TextTableItem,
  TextTable,
  ProductLanguage,
  ProductPropertyTranslation,
} from "../promaster-api";
export const notFoundProductId = "FD69FE50-20F0-11E8-DB4A-691F1A9AC44B";
export const metaProductId = "8DD62170-5DA0-11E7-9E17-6B8225A95BDB";
export const fricoProductId = "AF3E4180-CEA0-11E9-F858-2BAE52FFA150";
export const boxFanProductId = "21574700-3260-11E9-C35E-2BDDE95BCFDC";
export const centrifugalFanProductId = "1E0F5660-CEA0-11E9-ADAD-D7E33F9762E9";
export const offlineBoxFanProductsToSkipMarketCheck = [
  "5FEA7050-F5B0-11E9-EA85-3F8B643AF659",
  "816B98D0-F5B0-11E9-E003-5FDB48688046",
  "5EE6FB10-F5B0-11E9-98AB-51339EE6A6F2",
  "D3255EF0-F5B0-11E9-8725-978253AD7F8E",
  "E1411B10-F5B0-11E9-CFE2-09457A223DCF",
  "08244970-F5B0-11E9-E4B3-FB93B6E3E915",
  "830BDA30-F5B0-11E9-E903-21ECDD992C62",
  "19124B40-3260-11E9-9AB8-8F6F886CFB16",
  "CE8C8900-3260-11E9-C1C8-39A999EB88E3",
  "6146BABE-504A-4C4E-BCD4-39710599729C",
  "1C0F151C-3026-462F-ADDD-3F5740AAD550",
  "5DF39737-6C0B-4DCE-B979-C6A7A28B1CA4",
  "5EA6FB12-44F1-418C-96A7-B11A3D9CDA36",
];
