/* eslint-disable no-restricted-properties */
import * as IP from "shared-lib/interpolation";
import type * as QP from "shared-lib/query-product";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { UnitDivide, Units } from "uom-units";
import * as R from "ramda";
import * as CI from "./calculation-inputs";
import type {
  FanAirResult,
  WorkingPoint,
  Solution,
  SolutionInterpolated,
  Curve,
  SpeedControl,
} from "../result-items-types";
import * as Utils from "./utils";
import * as AirDensity from "../shared/air-density";
//import * as FEI from "./fan-FEI-curve";

export function calculate(
  speedControl: SpeedControl,
  airData: ReadonlyArray<QP.AirData>,
  airLimitsData: ReadonlyArray<QP.AirLimitsData>,
  desiredAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  desiredExternalPressure: Amount.Amount<Quantity.Pressure> | undefined,
  accessoryPressureCurves: ReadonlyArray<Curve>,
  airDensity: Amount.Amount<Quantity.Density> | undefined,
  fixedCurveId: string | undefined,
  ignoreWorkLimits: boolean,
  ignoreOutsideValidArea: boolean
): FanAirResult {
  const pressureData = airData.filter((p) => p.param === "Ps_v");

  const pressureCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Pascal,
    pressureData,
    ignoreWorkLimits ? [] : airLimitsData
  );
  const adjustedPressureCurves = applyAccessoryPressureDrops(pressureCurves, accessoryPressureCurves);

  //const feiCurves:ReadonlyArray<Curve> = FEI.createFeiCurves(airData);

  const maxControlVoltage = Math.max(...pressureCurves.map((c) => c.controlVoltage), 10);
  const maxSupplyVoltage = Math.max(...pressureCurves.map((c) => c.supplyVoltage), 10);
  const maxFrequency = Math.max(...pressureCurves.map((c) => c.controlFrequency), 10);
  const flows = airData.map((p) => p.flow);
  const minAirFlowLps = flows.length > 0 ? Math.min(...flows) : undefined;
  const maxAirFlowLps = flows.length > 0 ? Math.max(...flows) : undefined;
  const pressures = pressureData.map((p) => p.value);
  const minPressurePa = flows.length > 0 ? Math.min(...pressures) : undefined;
  const maxPressurePa = flows.length > 0 ? Math.max(...pressures) : undefined;

  const powerCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Watt,
    airData.filter((p) => p.param === "P_v"),
    []
  );

  const shaftPowerCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Watt,
    airData.filter((p) => p.param === "H_v"),
    []
  );
  const currentCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Ampere,
    airData.filter((p) => p.param === "I_v"),
    []
  );
  const rpmCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.RevolutionsPerMinute,
    airData.filter((p) => p.param === "r_v"),
    []
  );

  let diff: number | undefined = undefined;
  let distanceWorkMax: number | undefined = undefined;
  let distanceWorkDesired: number | undefined = undefined;
  let desiredOutsideValidArea: boolean | undefined = undefined;
  let airFlowLps: number | undefined = undefined;
  let externalPressurePa: number | undefined = undefined;
  let controlVoltageV: number | undefined = undefined;
  let controlVoltageP: number | undefined = undefined;
  let supplyVoltageV: number | undefined = undefined;
  let supplyVoltageP: number | undefined = undefined;

  let frequencyControlH: number | undefined = undefined;
  let frequencyControlP: number | undefined = undefined;

  let powerW: number | undefined = undefined;
  let currentI: number | undefined = undefined;
  let fanSpeedRpm: number | undefined = undefined;
  let sfpKwPerCmps: number | undefined = undefined;
  let efficiencyP: number | undefined = undefined;
  let voltageLowV: number | undefined = undefined;
  let voltageHighV: number | undefined = undefined;
  let voltageLowP: number | undefined = undefined;
  let voltageHighP: number | undefined = undefined;
  let fanSpeedLowRpm: number | undefined = undefined;
  let fanSpeedHighRpm: number | undefined = undefined;
  let airflowEfficiency: number | undefined = undefined;

  const desiredAirFlowLps = desiredAirFlow && Amount.valueAs(Units.LiterPerSecond, desiredAirFlow);

  const requiredPressureByDensity = AirDensity.calculateExternalPressureWithDensity(
    desiredExternalPressure,
    airDensity
  );
  const desiredExternalPressurePa = desiredExternalPressure && Amount.valueAs(Units.Pascal, desiredExternalPressure);
  const requiredPressureByDensityPa =
    requiredPressureByDensity && Amount.valueAs(Units.Pascal, requiredPressureByDensity);

  const workingPoint =
    desiredAirFlowLps && requiredPressureByDensityPa
      ? findWorkingPoint(
          speedControl,
          desiredAirFlowLps,
          requiredPressureByDensityPa,
          adjustedPressureCurves,
          fixedCurveId
        )
      : undefined;

  const validWorkingInterval =
    desiredAirFlowLps && requiredPressureByDensityPa
      ? findValidSystemCurveInterval(
          speedControl,
          desiredAirFlowLps,
          requiredPressureByDensityPa,
          adjustedPressureCurves
        )
      : undefined;

  if (desiredAirFlowLps && desiredExternalPressurePa && workingPoint) {
    airFlowLps = workingPoint.point.x;
    externalPressurePa = workingPoint.point.y;
    controlVoltageV = workingPoint.controlVoltage;
    controlVoltageP = (100 * workingPoint.controlVoltage) / maxControlVoltage;
    supplyVoltageV = workingPoint.supplyVoltage;
    supplyVoltageP = (100 * workingPoint.supplyVoltage) / maxSupplyVoltage;

    frequencyControlH = workingPoint.controlFrequency;
    frequencyControlP = (100 * workingPoint.controlFrequency) / maxFrequency;

    powerW = calcValueFromWorkingPoint(workingPoint, powerCurves);
    currentI = calcValueFromWorkingPoint(workingPoint, currentCurves);
    fanSpeedRpm = calcValueFromWorkingPoint(workingPoint, rpmCurves);
    const airFlowCmps = Utils.convertFromTo(airFlowLps, Units.LiterPerSecond, Units.CubicMeterPerSecond);
    sfpKwPerCmps = powerW && (0.001 * powerW) / airFlowCmps;
    efficiencyP = powerW && (100 * externalPressurePa * airFlowCmps) / powerW;
    const airFlowLowLps = 0.6 * airFlowLps;
    const k = calcK(desiredAirFlowLps, desiredExternalPressurePa);
    const pressureLowPa = calcY(k, airFlowLowLps);
    const workingPointLow = findWorkingPoint(speedControl, airFlowLowLps, pressureLowPa, adjustedPressureCurves);

    const airflowCubicFeetPerMinute = Utils.convertFromTo(airFlowLps, Units.LiterPerSecond, Units.CubicFeetPerMinute);
    airflowEfficiency = powerW && airflowCubicFeetPerMinute / powerW;

    voltageLowV = workingPointLow?.controlVoltage;
    voltageLowP = workingPointLow && (100 * workingPointLow.controlVoltage) / maxControlVoltage;
    fanSpeedLowRpm = workingPointLow && calcValueFromWorkingPoint(workingPointLow, rpmCurves);

    const maxCurve =
      adjustedPressureCurves.length > 0 ? adjustedPressureCurves[adjustedPressureCurves.length - 1] : undefined;
    const maxPoint = maxCurve && findSystemCurveIntersection(k, maxCurve);
    const minCurve = adjustedPressureCurves[0];
    const minPoint = minCurve && findSystemCurveIntersection(k, minCurve);
    const airFlowHighLps = maxPoint && 0.5 * (maxPoint.x + airFlowLps);
    const pressureHighPa = airFlowHighLps !== undefined ? calcY(k, airFlowHighLps) : undefined;
    const workingPointHigh =
      airFlowHighLps !== undefined &&
      pressureHighPa !== undefined &&
      findWorkingPoint(speedControl, airFlowHighLps, pressureHighPa, adjustedPressureCurves);
    voltageHighV = workingPointHigh ? workingPointHigh.controlVoltage : undefined;
    voltageHighP = workingPointHigh ? (100 * workingPointHigh.controlVoltage) / maxControlVoltage : undefined;
    fanSpeedHighRpm = workingPointHigh ? calcValueFromWorkingPoint(workingPointHigh, rpmCurves) : undefined;
    // Euclidean distance between the working point and the maximum workingpoint on the systemcurve
    distanceWorkMax =
      maxPoint &&
      Math.sqrt(Math.pow(maxPoint.x - (airFlowLps ?? 0), 2) + Math.pow(maxPoint.y - (externalPressurePa ?? 0), 2));

    // Euclidean distance between the working point and the desired working point (inputs)
    distanceWorkDesired = Math.sqrt(
      Math.pow(desiredAirFlowLps - (airFlowLps ?? 0), 2) +
        Math.pow(desiredExternalPressurePa - (externalPressurePa ?? 0), 2)
    );
    diff = (100.0 * (airFlowLps ?? 0 - desiredAirFlowLps)) / desiredAirFlowLps - 100.0;

    // The system curve intersection calculation has precision issues at low air flows.
    // This epison should hopefully account for this.
    const epsilon = 0.1;
    // Determine if the desired point is below the min curve or above the max curve
    const belowValidArea = minPoint
      ? Math.sqrt(minPoint.x ** 2 + minPoint.y ** 2) >
        Math.sqrt(desiredAirFlowLps ** 2 + desiredExternalPressurePa ** 2) + epsilon
      : undefined;
    const aboveValidArea = maxPoint
      ? Math.sqrt(maxPoint.x ** 2 + maxPoint.y ** 2) <
        Math.sqrt(desiredAirFlowLps ** 2 + desiredExternalPressurePa ** 2) - epsilon
      : undefined;

    desiredOutsideValidArea =
      !!aboveValidArea || (belowValidArea && adjustedPressureCurves.length > 1 && speedControl !== "None");
  }

  return {
    pressureCurves,
    adjustedPressureCurves,
    powerCurves,
    currentCurves,
    rpmCurves,
    speedControl,
    shaftPowerCurves,
    feiCurves: [],
    minAirFlow: Utils.maybeAmount(minAirFlowLps, Units.LiterPerSecond, 1),
    maxAirFlow: Utils.maybeAmount(maxAirFlowLps, Units.LiterPerSecond, 1),
    minPressure: Utils.maybeAmount(minPressurePa, Units.Pascal, 1),
    maxPressure: Utils.maybeAmount(maxPressurePa, Units.Pascal, 1),

    airDensity: airDensity,

    workingPoint: workingPoint,
    systemCurveMinValidPoint: validWorkingInterval?.min && {
      airFlow: Amount.create(validWorkingInterval.min.x, Units.LiterPerSecond, 1),
      pressure: Amount.create(validWorkingInterval.min.y, Units.Pascal, 1),
    },
    systemCurveMaxValidPoint: validWorkingInterval?.max && {
      airFlow: Amount.create(validWorkingInterval.max.x, Units.LiterPerSecond, 1),
      pressure: Amount.create(validWorkingInterval.max.y, Units.Pascal, 1),
    },
    diff: Utils.maybeAmount(diff, Units.Percent, 2),
    distanceWorkingPointToDesiredPoint: distanceWorkDesired,
    distanceWorkingPointToMaxPoint: distanceWorkMax,
    desiredPointIsOutsideValidArea: ignoreOutsideValidArea ? false : desiredOutsideValidArea,
    shaftPower: undefined,
    desiredAirFlow: Utils.maybeAmount(desiredAirFlowLps, Units.LiterPerSecond, 0),
    desiredExternalPressure: Utils.maybeAmount(desiredExternalPressurePa, Units.Pascal, 1),
    airFlow: Utils.maybeAmount(airFlowLps, Units.LiterPerSecond, 0),
    externalPressure: Utils.maybeAmount(externalPressurePa, Units.Pascal, 1),
    controlVoltage: controlVoltageV !== 0 ? Utils.maybeAmount(controlVoltageV, Units.Volt, 0) : undefined,
    controlVoltagePercent: controlVoltageP !== 0 ? Utils.maybeAmount(controlVoltageP, Units.Percent, 0) : undefined,

    frequencyControlHertz:
      frequencyControlH !== 0 && speedControl === "Frequency converter"
        ? Utils.maybeAmount(frequencyControlH, Units.Hertz, 0)
        : undefined,
    frequencyControlPercent:
      frequencyControlP !== 0 && speedControl === "Frequency converter"
        ? Utils.maybeAmount(frequencyControlP, Units.Percent, 0)
        : undefined,

    supplyVoltage: supplyVoltageV !== 0 ? Utils.maybeAmount(supplyVoltageV, Units.Volt, 0) : undefined,
    supplyVoltagePercent: supplyVoltageP !== 0 ? Utils.maybeAmount(supplyVoltageP, Units.Percent, 0) : undefined,
    power: Utils.maybeAmount(powerW, Units.Watt, 1),
    current: Utils.maybeAmount(currentI, Units.Ampere, 3),
    fanSpeed: Utils.maybeAmount(fanSpeedRpm, Units.RevolutionsPerMinute, 0),
    sfp: Utils.maybeAmount(sfpKwPerCmps, Units.KiloWattPerCubicMeterPerSecond, 2),
    airflowEfficiency: Utils.maybeAmount(
      airflowEfficiency,
      UnitDivide.volumeFlowByPower("CubicFeetPerMinutePerWatt", Units.CubicFeetPerMinute, Units.Watt),
      2
    ),
    efficiencyShaftPower: undefined,
    efficiency: Utils.maybeAmount(efficiencyP, Units.Percent, 1),
    voltageLow: Utils.maybeAmount(voltageLowV, Units.Volt, 0),
    voltageHigh: Utils.maybeAmount(voltageHighV, Units.Volt, 0),
    voltagePercentLow: Utils.maybeAmount(voltageLowP, Units.Percent, 0),
    voltagePercentHigh: Utils.maybeAmount(voltageHighP, Units.Percent, 0),
    fanSpeedLow: Utils.maybeAmount(fanSpeedLowRpm, Units.RevolutionsPerMinute, 0),
    fanSpeedHigh: Utils.maybeAmount(fanSpeedHighRpm, Units.RevolutionsPerMinute, 0),
    diagramDrawMethod: "Default",
  };
}

function applyAccessoryPressureDrops(
  pressureCurves: ReadonlyArray<Curve>,
  accessoryCurves: ReadonlyArray<Curve>
): ReadonlyArray<Curve> {
  return pressureCurves.map((c) => ({
    ...c,
    spline: accessoryCurves.reduce((a, b) => IP.splineSubtract(a, b.spline), c.spline),
  }));
}

function calcK(x: number, y: number): number {
  return y / (x * x);
}

function calcY(k: number, newX: number): number {
  const newY = k * newX * newX;
  return newY;
}

export function mergeMinMaxPressureAndAirFlow(fanResults: ReadonlyArray<FanAirResult>): ReadonlyArray<FanAirResult> {
  const minAfCmph = Math.min(
    ...fanResults
      .map((r) => r.minAirFlow)
      .filter((r): r is Amount.Amount<Quantity.VolumeFlow> => !!r)
      .map((r) => Amount.valueAs(Units.CubicMeterPerHour, r))
  );
  const maxAfCmph = Math.max(
    ...fanResults
      .map((r) => r.maxAirFlow)
      .filter((r): r is Amount.Amount<Quantity.VolumeFlow> => !!r)
      .map((r) => Amount.valueAs(Units.CubicMeterPerHour, r))
  );
  const minPsPa = Math.min(
    ...fanResults
      .map((r) => r.minPressure)
      .filter((r): r is Amount.Amount<Quantity.Pressure> => !!r)
      .map((r) => Amount.valueAs(Units.Pascal, r))
  );
  const maxPsPa = Math.max(
    ...fanResults
      .map((r) => r.maxPressure)
      .filter((r): r is Amount.Amount<Quantity.Pressure> => !!r)
      .map((r) => Amount.valueAs(Units.Pascal, r))
  );
  const minAirFlow = Number.isFinite(minAfCmph) ? Amount.create(minAfCmph, Units.CubicMeterPerHour) : undefined;
  const maxAirFlow = Number.isFinite(maxAfCmph) ? Amount.create(maxAfCmph, Units.CubicMeterPerHour) : undefined;
  const minPressure = Number.isFinite(minPsPa) ? Amount.create(minPsPa, Units.Pascal) : undefined;
  const maxPressure = Number.isFinite(maxPsPa) ? Amount.create(maxPsPa, Units.Pascal) : undefined;
  return fanResults.map((r) => ({ ...r, minAirFlow, maxAirFlow, minPressure, maxPressure }));
}

export function findWorkingPoint(
  speedControl: SpeedControl,
  x: number,
  y: number,
  curves: ReadonlyArray<Curve>,
  fixedCurveId?: string
): WorkingPoint | undefined {
  if (curves.length === 0) {
    return undefined;
  }
  // Build system curve
  const k = calcK(x, y);

  // A working point is only valid if the system curves passas through all fan curves
  const nonIntersecting = curves.find((c) => {
    const int = findSystemCurveIntersection(k, c);
    if (!int) {
      return true;
    }
    if (int.x < c.workMin || int.x > c.workMax) {
      return true;
    }
    return false;
  });
  if (nonIntersecting) {
    return undefined;
  }

  if (speedControl === "None") {
    return findWorkingPointNoSpeedControl(k, curves);
  } else if (speedControl === "Transformer") {
    return findWorkingPointSteppedSpeedControl(k, x, curves, fixedCurveId);
  } else if (speedControl === "Stepless" || speedControl === "Frequency converter") {
    return findWorkingPointSteplessSpeedControl(k, x, y, curves);
  }
  return undefined;
}

export function findValidSystemCurveInterval(
  speedControl: SpeedControl,
  x: number,
  y: number,
  curves: ReadonlyArray<Curve>
):
  | {
      readonly min: IP.Vec2 | undefined;
      readonly max: IP.Vec2 | undefined;
    }
  | undefined {
  if (curves.length === 0) {
    return undefined;
  }

  const k = calcK(x, y);

  const minX = Math.min(...curves.map((c) => c.spline.xMin));
  const maxX = Math.max(...curves.map((c) => c.spline.xMax));

  if (speedControl === "None") {
    const wp = findWorkingPointNoSpeedControl(k, curves);
    if (!wp) {
      return undefined;
    }
    return {
      min: undefined,
      max: wp.point,
    };
  } else if (speedControl === "Transformer") {
    const min = findWorkingPointSteppedSpeedControl(k, minX, curves, undefined)?.point;
    const max = findWorkingPointSteppedSpeedControl(k, maxX, curves, undefined)?.point;
    if (!min || !max) {
      return undefined;
    }
    return {
      min: min,
      max: max,
    };
  } else if (speedControl === "Stepless") {
    const minY = k * minX * minX;
    const maxY = k * maxX * maxX;
    const min = findWorkingPointSteplessSpeedControl(k, minX, minY, curves)?.point;
    const max = findWorkingPointSteplessSpeedControl(k, maxX, maxY, curves)?.point;
    if (!min || !max) {
      return undefined;
    }
    return {
      min: min,
      max: max,
    };
  }
  return undefined;
}

function findWorkingPointSteplessSpeedControl(
  k: number,
  x: number,
  y: number,
  curves: ReadonlyArray<Curve>
): WorkingPoint | undefined {
  const curveYs = R.fromPairs(
    curves.map((c) => [c.id, IP.splineGetPoint(x, c.spline)] as R.KeyValuePair<R.Prop, number | undefined>)
  );
  const curvePoints = R.fromPairs(
    curves.map(
      (c) =>
        [
          c.id,
          findSystemCurveIntersection(k, c) ||
            IP.vec2Create(c.spline.xMin, IP.splineGetPoint(c.spline.xMin, c.spline) || 0),
        ] as R.KeyValuePair<R.Prop, IP.Vec2>
    )
  );

  const lowerCurve = R.find((c) => {
    const curveY = curveYs[c.id];
    if (curveY !== undefined) {
      return curveY < y;
    }
    const curvePoint = curvePoints[c.id];
    return curvePoint.x < x;
  }, R.reverse(curves));

  const higherCurve = R.find((c) => {
    const curveY = curveYs[c.id];
    if (curveY !== undefined) {
      return curveY > y;
    }
    const curvePoint = curvePoints[c.id];
    return curvePoint.x > x;
  }, curves);

  if (!lowerCurve && !higherCurve) {
    return undefined;
  } else if (!higherCurve) {
    const point = curvePoints[lowerCurve.id];
    if (point.x < lowerCurve.workMin || point.x > lowerCurve.workMax) {
      return undefined;
    }
    return {
      point: point,
      controlVoltage: lowerCurve.controlVoltage,
      supplyVoltage: lowerCurve.supplyVoltage,
      controlFrequency: lowerCurve.controlFrequency,
      solution: {
        type: "Curve",
        curveId: lowerCurve.id,
        point: point,
        ratio: 1,
      },
    };
  } else if (!lowerCurve) {
    const point = curvePoints[higherCurve.id];
    if (point.x < higherCurve.workMin || point.x > higherCurve.workMax) {
      return undefined;
    }
    return {
      point: point,
      controlVoltage: higherCurve.controlVoltage,
      supplyVoltage: higherCurve.supplyVoltage,
      controlFrequency: higherCurve.controlFrequency,
      solution: {
        type: "Curve",
        curveId: higherCurve.id,
        point: point,
        ratio: 1,
      },
    };
  }

  const point = IP.vec2Create(x, y);

  // Build lower solution
  let lower: Solution | undefined = undefined;
  const lowerY = curveYs[lowerCurve.id];
  let lowerControlVoltage = lowerCurve.controlVoltage;
  let lowerSupplyVoltage = lowerCurve.supplyVoltage;
  let lowerControlFrequency = lowerCurve.controlFrequency;
  if (lowerY !== undefined) {
    lower = {
      type: "Curve",
      curveId: lowerCurve.id,
      point: IP.vec2Create(x, lowerY),
      ratio: 1,
    };
  } else {
    lower = buildEdgeSolution(x, lowerCurve.spline.xMax, lowerCurve, higherCurve.spline.xMax, higherCurve);
    lowerControlVoltage =
      lowerCurve.controlVoltage * lower.lower.ratio + higherCurve.controlVoltage * lower.higher.ratio;
    lowerSupplyVoltage = lowerCurve.supplyVoltage * lower.lower.ratio + higherCurve.supplyVoltage * lower.higher.ratio;
    lowerControlFrequency =
      lowerCurve.controlFrequency * lower.lower.ratio + higherCurve.controlFrequency * lower.higher.ratio;
  }

  // Build higher solution
  let higher: Solution | undefined = undefined;
  const higherY = curveYs[higherCurve.id];
  let higherControlVoltage = higherCurve.controlVoltage;
  let higherSupplyVoltage = higherCurve.supplyVoltage;
  let higherControlFrequency = higherCurve.controlFrequency;
  if (higherY !== undefined) {
    higher = {
      type: "Curve",
      curveId: higherCurve.id,
      point: IP.vec2Create(x, higherY),
      ratio: 1,
    };
  } else {
    higher = buildEdgeSolution(x, lowerCurve.spline.xMin, lowerCurve, higherCurve.spline.xMin, higherCurve);
    higherControlVoltage =
      lowerCurve.controlVoltage * higher.lower.ratio + higherCurve.controlVoltage * higher.higher.ratio;
    higherSupplyVoltage =
      lowerCurve.supplyVoltage * higher.lower.ratio + higherCurve.supplyVoltage * higher.higher.ratio;
    higherControlFrequency =
      lowerCurve.controlFrequency * higher.lower.ratio + higherCurve.controlFrequency * higher.higher.ratio;
  }

  if (!lower || !higher || lower.point.y > y || higher.point.y < y) {
    return undefined;
  }

  const higherWorkMin = IP.vec2Create(
    higherCurve.workMin,
    IP.splineGetPoint(higherCurve.workMin, higherCurve.spline) || 0
  );
  const lowerWorkMin = IP.vec2Create(lowerCurve.workMin, IP.splineGetPoint(lowerCurve.workMin, lowerCurve.spline) || 0);
  const minSide = getSideOfVerticalLine(point, lowerWorkMin, higherWorkMin);
  if (minSide === "Left") {
    console.log("min", { lowerWorkMin, higherWorkMin, point });
    return undefined;
  }

  const higherWorkMax = IP.vec2Create(
    higherCurve.workMax,
    IP.splineGetPoint(higherCurve.workMax, higherCurve.spline) || 0
  );
  const lowerWorkMax = IP.vec2Create(lowerCurve.workMax, IP.splineGetPoint(lowerCurve.workMax, lowerCurve.spline) || 0);
  const maxSide = getSideOfVerticalLine(point, lowerWorkMax, higherWorkMax);
  if (maxSide === "Right") {
    console.log("max");
    return undefined;
  }

  const lowerDist = y - lower.point.y;
  const higherDist = higher.point.y - y;
  lower = {
    ...lower,
    ratio: 1 - lowerDist / (lowerDist + higherDist),
  };
  higher = {
    ...higher,
    ratio: 1 - higherDist / (lowerDist + higherDist),
  };

  const controlVoltage = lowerControlVoltage * lower.ratio + higherControlVoltage * higher.ratio;
  const supplyVoltage = lowerSupplyVoltage * lower.ratio + higherSupplyVoltage * higher.ratio;
  const controlFrequency = lowerControlFrequency * lower.ratio + higherControlFrequency * higher.ratio;
  return {
    point: point,
    controlVoltage: controlVoltage,
    supplyVoltage: supplyVoltage,
    controlFrequency: controlFrequency,
    solution: {
      type: "Interpolated",
      lower: lower,
      higher: higher,
      point: point,
      ratio: 1,
    },
  };
}

function findWorkingPointSteppedSpeedControl(
  k: number,
  x: number,
  curves: ReadonlyArray<Curve>,
  fixedCurveId: string | undefined
): WorkingPoint | undefined {
  const fixedCurveIx = curves.findIndex((c) => c.id === fixedCurveId);
  if (fixedCurveIx >= 0) {
    const fixedCurve = curves[fixedCurveIx];
    const fixedPoint = findSystemCurveIntersection(k, fixedCurve);
    return createWorkingPoint(fixedPoint, fixedCurve, fixedCurveIx);
  }

  const curvesWithPoint = curves.map((c, i) => ({ curve: c, index: i, point: findSystemCurveIntersection(k, c) }));
  const { point, curve, index } =
    curvesWithPoint
      .filter((r) => !!r.point)
      .sort((a, b) => a.point!.x - b.point!.x)
      .find((r) => !!r.point && r.point.x > x) ?? curvesWithPoint[curvesWithPoint.length - 1];
  if (!point || point.x < curve.workMin || point.x > curve.workMax) {
    return undefined;
  }
  return createWorkingPoint(point, curve, index);
}

function findWorkingPointNoSpeedControl(k: number, curves: ReadonlyArray<Curve>): WorkingPoint | undefined {
  const maxCurve = curves[curves.length - 1];
  const point = findSystemCurveIntersection(k, maxCurve);
  if (!point || point.x < maxCurve.workMin || point.x > maxCurve.workMax) {
    return undefined;
  }
  return createWorkingPoint(point, maxCurve, curves.length - 1);
}

function createWorkingPoint(point: IP.Vec2 | undefined, curve: Curve, curveIndex: number): WorkingPoint | undefined {
  if (!point) {
    return undefined;
  }
  return {
    point: point,
    controlVoltage: curve.controlVoltage,
    supplyVoltage: curve.supplyVoltage,
    controlFrequency: curve.controlFrequency,
    curveId: curve.id,
    curveIndex: curveIndex,
    solution: {
      type: "Curve",
      curveId: curve.id,
      point: point,
      ratio: 1,
    },
  };
}

type Side = "Left" | "Right";

function getSideOfVerticalLine(point: IP.Vec2, lineMin: IP.Vec2, lineMax: IP.Vec2): Side {
  const lineMinX = Math.min(lineMin.x, lineMax.x);
  const lineMaxX = Math.max(lineMin.x, lineMax.x);
  if (point.x < lineMinX) {
    return "Left";
  }
  if (point.x > lineMaxX) {
    return "Right";
  }
  if (lineMin.x === lineMax.x) {
    return point.x < lineMin.x ? "Left" : "Right";
  }
  if (lineMin.y === lineMax.y) {
    return point.y > lineMin.y ? "Left" : "Right";
  }
  const dx = lineMax.x - lineMin.x;
  const dy = lineMax.y - lineMin.y;
  const k = dy / dx;
  const m = lineMin.y - k * lineMin.x;
  const y = k * point.x + m;
  if (point.y > y) {
    return "Left";
  } else {
    return "Right";
  }
}

function buildEdgeSolution(
  x: number,
  lowerX: number,
  lowerCurve: Curve,
  higherX: number,
  higherCurve: Curve
): SolutionInterpolated {
  // Find edge end points
  const lowerY = IP.splineGetPoint(lowerX, lowerCurve.spline);
  const lowerPoint = IP.vec2Create(lowerX, lowerY || 0);
  const higherY = IP.splineGetPoint(higherX, higherCurve.spline);
  const higherPoint = IP.vec2Create(higherX, higherY || 0);

  // Find edge point
  const edgeK = (higherPoint.y - lowerPoint.y) / (higherPoint.x - lowerPoint.x);
  const edgeM = higherPoint.y - edgeK * higherPoint.x;
  const edgeY = edgeK * x + edgeM;

  // Calculate ratios
  const lowerDist = Math.abs(x - lowerPoint.x);
  const higherDist = Math.abs(higherPoint.x - x);
  const lowerRatio = 1.0 - lowerDist / (lowerDist + higherDist);
  const higherRatio = 1.0 - higherDist / (lowerDist + higherDist);

  return {
    type: "Interpolated",
    lower: {
      type: "Curve",
      curveId: lowerCurve.id,
      point: lowerPoint,
      ratio: lowerRatio,
    },
    higher: {
      type: "Curve",
      curveId: higherCurve.id,
      point: higherPoint,
      ratio: higherRatio,
    },
    point: IP.vec2Create(x, edgeY),
    ratio: 1,
  };
}

export function calcValueFromWorkingPoint(
  workingPoint: WorkingPoint,
  curves: ReadonlyArray<Curve>
): number | undefined {
  return getSolutionValue(curves, workingPoint.solution);
}

function getSolutionValue(curves: ReadonlyArray<Curve>, solution: Solution): number | undefined {
  if (solution.type === "Curve") {
    const curve = curves.find((c) => c.id === solution.curveId);
    return curve && (IP.splineGetPoint(solution.point.x, curve.spline) || 0) * solution.ratio;
  } else if (solution.type === "Interpolated") {
    const lowerValue = getSolutionValue(curves, solution.lower);
    const higherValue = getSolutionValue(curves, solution.higher);
    if (lowerValue === undefined || higherValue === undefined) {
      return undefined;
    }
    return solution.ratio * (lowerValue + higherValue);
  }
  return undefined;
}

export function findSystemCurveIntersection(k: number, curve: Curve): IP.Vec2 | undefined {
  return IP.findSystemCurveIntersection(k, curve.spline);
}
