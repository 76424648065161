import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { Amount } from "uom";
import type { Quantity} from "uom-units";
import { Units } from "uom-units";
import { CustomUnitsLookup } from "shared-lib/uom";
import type { Input, Response } from "./types";
import type {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  InputParam,
  System,
  ResultQuery} from "../types";
import {
  createInputMapperError,
  createInputMapperSuccess
} from "../types";
import * as Messages from "../messages";
import * as Attributes from "../shared/attributes";

const msgSource = "CentrifugalFanInputMapper";

export function getCalcParams(params: string, _: Attributes.Attributes): ReadonlyArray<InputParam> {
  if (params === "search") {
    return [];
  }
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour",
        CustomUnitsLookup
      ),
      quantity: "VolumeFlow",
      fieldName: "airFlow",
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "externalPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "externalPressure=0.1:Pascal~999999:Pascal",
        CustomUnitsLookup
      ),
      quantity: "Pressure",
      fieldName: "airPressure",
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "airDensityCalculationMethod",
      values: [
        {
          value: 0,
          name: "AirDensity",
        },
        {
          value: 1,
          name: "Pressure",
        },
        {
          value: 2,
          name: "SeaLevel",
        },
      ],
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensity",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=0", CustomUnitsLookup),
      quantity: "Density",
      fieldName: "airDensity",
      defaultValue: Amount.create(1.204, Units.KilogramPerCubicMeter),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensity>0:KilogramPerCubicMeter", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityPressure",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=1", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
      defaultValue: Amount.create(101325, Units.Pascal),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityPressure>0:Pascal", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensitySeaLevel",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=2", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensitySeaLevel!=null", CustomUnitsLookup),
      quantity: "Length",
      fieldName: "airDensitySeaLevel",
      defaultValue: Amount.create(0, Units.Meter),
    },
    // {
    //   type: "Discrete",
    //   group: "calculationParams",
    //   name: "soundPressureDistance",
    //   values: [
    //     {
    //       value: 10,
    //       name: "1.0 m"
    //     },
    //     {
    //       value: 15,
    //       name: "1.5 m"
    //     },
    //     {
    //       value: 30,
    //       name: "3.0 m"
    //     },
    //     {
    //       value: 50,
    //       name: "5.0 m"
    //     }
    //   ]
    // }
  ];
}

export function getQuery(productId: string, variantId: string | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_Motor: QP.tableByProductId(QP.centrifugalFanProductId, "ct_CentrifugalMotor"),
    ct_CentrifugalFan: QP.tableByProductId(productId, "ct_CentrifugalFan"),
    ct_AirData2: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirData2"),
    ct_AirLimitsData: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirLimitsData"),
    ct_SoundData: QP.tableFromMtOrByProductId(productId, variantId, "ct_SoundData"),
  });
}

export function getResultsQuery(system: System, componentId: string): ReadonlyArray<ResultQuery> {
  return system.components
    .filter((c) => c.id !== componentId && c.resultItems.length > 0)
    .map((c) => {
      const resultItems = c.resultItems.map((i) => i.name);
      return {
        componentId: c.id,
        resultItems: resultItems,
      };
    });
}

export function map(
  { calcParams, attributes }: ComponentInput,
  queryResultMap: Response
): InputMapperSuccess<Input> | InputMapperError {
  const { ct_CentrifugalFan, ct_Motor } = queryResultMap;

  const configurableFan = Attributes.getStringOrDefault("promaster-centrifugal-fan-type", attributes, 0) === "Configurable fan";
  //const impellerDiameter = Attributes.getFloatOrDefault("td-PROP-diameter-impeller", attributes, Attributes.getFloatOrDefault("size-diameter-impeller-BASE-ALL", attributes, 0));
  const impellerDiameterFan = Attributes.getFloatOrDefault("size-diameter-impeller-BASE-ALL", attributes, 0);

  if (impellerDiameterFan === 0) {
    console.warn("Attribute size-diameter-impeller-BASE-ALL not found");
    return createInputMapperError([Messages.ProductDataError_NoDataAvailable(msgSource)]);
  }

  // We select fan given the attribute size-diameter-impeller-BASE-ALL = ct_CentrifugalFan.impeller_diameter
  const centrifugalFan = ct_CentrifugalFan.find((r) => r.impeller_diameter === impellerDiameterFan);

  if (!centrifugalFan) {
    console.warn("No centrifugal fan found");
    return createInputMapperError([Messages.ProductDataError_NoDataAvailable(msgSource)]);
  }

  const airDensity = PropertyValueSet.getAmount<Quantity.Density>("airDensity", calcParams);

  if (!airDensity) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "airDensity")]);
  }

  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const externalPressure = PropertyValueSet.getAmount<Quantity.Pressure>("externalPressure", calcParams);

  const airMeasurement = Attributes.getMeasurementNo("measurement-number-air", attributes);
  const soundMeasurement = Attributes.getMeasurementNo("measurement-number-sound", attributes);
  const motorPowerAttribute = Attributes.getInt("input-power-motor-BASE-ALL", attributes);
  const airData = (airMeasurement && queryResultMap.ct_AirData2[airMeasurement]) || [];
  const airLimitsData = (airMeasurement && queryResultMap.ct_AirLimitsData[airMeasurement]) || [];
  const soundData = (soundMeasurement && queryResultMap.ct_SoundData[soundMeasurement]) || [];
  const numberOfFanBlades = Attributes.getInt("quantity-impeller-blades", attributes);
  const m3WeightKg = Attributes.getFloat("M3-WEIGHT-NET-METRIC", attributes);

  // QC and QT max are in m3/h in Promaster, we need l/s
  const qcMaxValue =  Attributes.getFloat("air-flow-AMCA-item-MAX", attributes);
  const qtMaxValue =  Attributes.getFloat("air-flow-AMCA-measurement-MAX", attributes);
  const qcMax = qcMaxValue !== undefined ? Amount.valueAs(Units.LiterPerSecond, Amount.create(qcMaxValue, Units.CubicMeterPerHour, 10)) : undefined;
  const qtMax = qtMaxValue !== undefined ? Amount.valueAs(Units.LiterPerSecond, Amount.create(qtMaxValue, Units.CubicMeterPerHour, 10)) : undefined;

  const impellerDiameterMeasurement =Attributes.getFloat("size-diameter-impeller-measurement-BASE-ALL", attributes);
  

  const voltage = Attributes.getInt("input-voltage-NOM", attributes);
  const frequency = getFrequency(attributes);

  return createInputMapperSuccess({
    airFlow: airFlow,
    configurableFan: configurableFan,
    externalPressure: externalPressure,
    airDensity: airDensity,
    calcParams: calcParams,
    airData: airData.filter((t) => t.flow > 0 && t.value > 0), // airData,
    airLimitsData: airLimitsData,
    centrifugalFan: centrifugalFan,
    centrifugalMotors: ct_Motor,
    soundData: soundData,
    voltage: voltage,
    frequency: frequency,
    motorPowerAttribute: motorPowerAttribute,
    impellerDiameterFanMM: impellerDiameterFan,
    numberOfFanBlades: numberOfFanBlades,
    m3WeightKg: m3WeightKg,
    qcMax,
    qtMax,
    impellerDiameterMeasurementMM: impellerDiameterMeasurement
  });
}

export function getFrequency(attributes: Attributes.Attributes): number | undefined {
  const variant50 = Attributes.matchesAttribute("input-frequency-MULTI", attributes, "50");
  const variant60 = Attributes.matchesAttribute("input-frequency-MULTI", attributes, "60");
  if (variant50 && variant60) {
    return 5060;
  }
  if (variant50) {
    return 50;
  }
  if (variant60) {
    return 60;
  }
  return undefined;
}
