/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import type * as SC from "shared-lib/system-calculator";
import type * as QP from "shared-lib/query-product";
import type * as PC from "shared-lib/product-codes";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import type * as Texts from "shared-lib/language-texts";
import type { ResultItemMap } from "shared-lib/system-calculator/result-items-types";
import type { NavigateToItemNo } from "types";
import { ResultRowContainer } from "client-lib/elements";
import type {
  VisualizerOwnProps,
  CalcParamsChangedCallback,
  VisualizerProductProps,
  SingleVisualizerOwnProps,
} from "./types";
import { TableVisualizerContainer } from "./table-visualizer";
import { OctaveBandsTableFanVisualizerContainer } from "./octave-bands-table-fan-visualizer";
import { OctaveBandsTableHruVisualizerContainer } from "./octave-bands-table-hru-visualizer";
import { HeatRecoveryUnitDiagramsVisualizerContainer } from "./heat-recovery-unit-diagrams-visualizer";
import { FanDiagramsVisualizerContainer } from "./fan-diagrams-visualizer";
import { CentrifugalFanDiagramsVisualizerContainer } from "./centrifugal-fan-diagrams-visualizer";
import { BoxFanDiagramsVisualizerContainer } from "./box-fan-diagrams-visualizer";
import { Base64DiagramVisualizerContainer } from "./base64-diagram-visualizer";
import { BoxFanRelatedProductsVisualizerContainer } from "./box-fan-related-products-visualizer";
import { MultiColumnTableVisualizerContainer } from "./multi-column-table-visualizer";
import { SoundPressureLevelVisualizerContainer } from "./sound-pressure-level";
import { SoundPressureLevelWithDistanceVisualizerContainer } from "./sound-pressure-level-with-distance";
import { AmcaStatementsVisualizerContainer } from "./amca-statements-visualizer";

export interface ProductProps {
  readonly system: SC.System;
  readonly productId: string;
  readonly codes: PC.ProductCodes;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly calcParamsChanged: CalcParamsChangedCallback;
  readonly resultItemOutputMap: SC.ResultItemOutputMap | undefined;
  readonly resultViewsToShow: QP.ResultViewsTable;
  readonly navigateToItemNo?: NavigateToItemNo;
  readonly isCompare: boolean;
}

export interface Props {
  readonly translate: Texts.TranslateFunction;
  readonly market: string;
  readonly language: string;
  readonly showDownload?: boolean;
  readonly shopUrl?: string;
  readonly productsProps: ReadonlyArray<ProductProps | undefined>;
}

export function ResultView(props: Props): React.ReactElement {
  // Pair comparable result views
  const allViews = R.unnest(props.productsProps.map((pp) => pp?.resultViewsToShow || [])) as QP.ResultViewsTable;
  const resultViewsToShow = R.uniqBy((v) => v.visualizer + v.result_item + v.visualizer_params, allViews);
  return (
    <div className="space-y-24">
      {resultViewsToShow.map((rv, i) =>
        renderVisualizer(
          props,
          `${i}_${rv.result_item + rv.visualizer + rv.visualizer_params}`,
          rv.visualizer,
          rv.visualizer_params,
          rv.result_item.split(";")
        )
      )}
    </div>
  );
}

function renderVisualizer(
  props: Props,
  key: string,
  visualizerName: QP.VisualizerName,
  visualizerParams: string,
  resultItemNames: ReadonlyArray<string>
): React.ReactElement {
  if (props.productsProps.every((pp) => !pp?.resultItemOutputMap)) {
    return <span key={key} />;
  }

  const renderIt = (visualizer: React.ComponentClass | React.FunctionComponent): React.ReactElement<any> =>
    renderVisualizerImpl(props, key, visualizer as any, visualizerParams, resultItemNames);

  const renderItColumns = (visualizer: React.ComponentClass | React.FunctionComponent): React.ReactElement<any> =>
    renderVisualizerColumnsImpl(props, key, visualizer as any, visualizerParams, resultItemNames);

  switch (visualizerName) {
    case "Table":
      return renderIt(TableVisualizerContainer);
    case "OctaveBandsTableFan":
      return renderIt(OctaveBandsTableFanVisualizerContainer);
    case "OctaveBandsTableHru":
      return renderIt(OctaveBandsTableHruVisualizerContainer);
    case "HeatRecoveryUnitDiagrams":
      return renderIt(HeatRecoveryUnitDiagramsVisualizerContainer);
    case "FanDiagrams":
      return renderIt(FanDiagramsVisualizerContainer);
    case "MultiColumnTable":
      return renderIt(MultiColumnTableVisualizerContainer);
    case "SoundPressureLevel":
      return renderIt(SoundPressureLevelVisualizerContainer);
    case "SoundPressureLevelWithDistance":
      return renderIt(SoundPressureLevelWithDistanceVisualizerContainer);
    case "BoxFanDiagrams":
      return renderIt(BoxFanDiagramsVisualizerContainer);
    case "Base64Diagram":
      return renderIt(Base64DiagramVisualizerContainer);
    case "CentrifugalFanDiagrams":
      return renderIt(CentrifugalFanDiagramsVisualizerContainer);
    case "BoxFanRelatedProducts":
      return renderItColumns(BoxFanRelatedProductsVisualizerContainer);
    case "AmcaStatements":
      return renderIt(AmcaStatementsVisualizerContainer);
    default:
      return exhaustiveCheck(visualizerName, true);
  }
}

function renderVisualizerImpl(
  props: Props,
  key: string,
  visualizer: React.FunctionComponent,
  visualizerParams: string,
  resultItemNames: ReadonlyArray<string>
): React.ReactElement {
  const { translate, market, language, showDownload, shopUrl, productsProps } = props;
  const visualizerProductProps = productsProps.map((pp) => pp && createVisualizerProductProps(pp, resultItemNames));
  if (visualizerProductProps.every((p) => !p)) {
    return <div key={key + "no-render"} />;
  } else {
    const products = visualizerProductProps as ReadonlyArray<VisualizerProductProps>;
    return React.createElement<VisualizerOwnProps>(visualizer as any, {
      translate,
      market,
      language,
      key,
      showDownload,
      visualizerParams,
      shopUrl,
      products,
    });
  }
}

function createVisualizerProductProps(
  productProps: ProductProps,
  resultItemNames: ReadonlyArray<string>
): VisualizerProductProps | undefined {
  const {
    resultItemOutputMap,
    productId,
    codes,
    variant,
    calcParams,
    calcParamsChanged,
    system,
    navigateToItemNo,
    isCompare,
  } = productProps;
  if (!resultItemOutputMap) {
    throw new Error("ResultItemOutputMap should not be undefined");
  }

  // Pick the result items to give to the visualizer
  const resultItemsOutput = resultItemNames.map((name) => resultItemOutputMap[name]);

  // Check that they are of type OutputMapperSuccess
  // If not then we just render an error
  const failures = resultItemsOutput.filter((r) => !r || r.type !== "OutputMapperSuccess");
  if (failures.length > 0) {
    return undefined;
  } else {
    const resultItemsOutputToUseMap = R.pick(resultItemNames, resultItemOutputMap);
    const resultItemMap: ResultItemMap = R.map(
      (r: SC.OutputMapperResult) => r.type === "OutputMapperSuccess" && r.result,
      resultItemsOutputToUseMap
    ) as ResultItemMap;
    const configKey = `${productId}_${PropertyValueSet.toString(variant)}_${PropertyValueSet.toString(calcParams)}`;
    return {
      configKey,
      system,
      productId,
      codes,
      variant,
      calcParams,
      calcParamsChanged,
      resultItemMap,
      navigateToItemNo,
      isCompare,
    };
  }
}

function renderVisualizerColumnsImpl(
  props: Props,
  key: string,
  visualizer: React.ComponentClass | React.FunctionComponent,
  visualizerParams: string,
  resultItemNames: ReadonlyArray<string>
): React.ReactElement {
  return (
    <ResultRowContainer key={key}>
      {...props.productsProps.map((pp, idx) =>
        pp
          ? renderSingleVisualizerImpl(props, `${key}_${idx}`, pp, visualizer, visualizerParams, resultItemNames)
          : null
      )}
    </ResultRowContainer>
  );
}

function renderSingleVisualizerImpl(
  props: Props,
  key: string,
  productProps: ProductProps,
  visualizer: React.ComponentClass | React.FunctionComponent,
  visualizerParams: string,
  resultItemNames: ReadonlyArray<string>
): React.ReactElement {
  const { translate, market, language, showDownload, shopUrl } = props;
  const {
    resultItemOutputMap,
    productId,
    codes,
    variant,
    calcParams,
    calcParamsChanged,
    system,
    navigateToItemNo,
  } = productProps;
  if (!resultItemOutputMap) {
    throw new Error("ResultItemOutputMap should not be undefined");
  }

  // Pick the result items to give to the visualizer
  const resultItemsOutput = resultItemNames.map((name) => resultItemOutputMap[name]);

  // Check that they are of type OutputMapperSuccess
  // If not then we just render an error
  const failures = resultItemsOutput.filter((r) => !r || r.type !== "OutputMapperSuccess");
  if (failures.length > 0) {
    return <div key={key + "no-render"} />;
  } else {
    const resultItemsOutputToUseMap = R.pick(resultItemNames, resultItemOutputMap);
    const resultItemMap: ResultItemMap = R.map(
      (r: SC.OutputMapperResult) => r.type === "OutputMapperSuccess" && r.result,
      resultItemsOutputToUseMap
    ) as ResultItemMap;

    const variantId = codes.variantId;

    return React.createElement<SingleVisualizerOwnProps>(visualizer as any, {
      system,
      translate,
      market,
      language,
      key,
      productId,
      variantId,
      variant,
      calcParams,
      calcParamsChanged,
      showDownload,
      visualizerParams,
      resultItemMap,
      shopUrl,
      navigateToItemNo,
    });
  }
}
