import type * as QP from "shared-lib/query-product";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import * as Messages from "../messages";
import * as Lautner from "../dll/exchanger-lautner";
import * as Recutech from "../dll/exchanger-recutech";
import * as Recair from "../dll/exchanger-recair";
import type { FanAirResult, ExchangerResult } from "../result-items-types";
import * as Attributes from "./attributes";

export async function calculate(
  supplyFan: FanAirResult,
  supplyInletTemperature: Amount.Amount<Quantity.Temperature>,
  supplyInletHumidity: Amount.Amount<Quantity.RelativeHumidity>,
  extractFan: FanAirResult,
  extractInletTemperature: Amount.Amount<Quantity.Temperature>,
  extractInletHumidity: Amount.Amount<Quantity.RelativeHumidity>,
  attributes: Attributes.Attributes,
  tempEfficiencyCorrections: QP.TempEfficiencyCorrectionTable,
  passiveHouse: boolean
): Promise<ExchangerResult | undefined> {
  if (!supplyFan.workingPoint || !extractFan.workingPoint) {
    return undefined;
  }
  const supplyInletTemperatureC = Amount.valueAs(Units.Celsius, supplyInletTemperature);
  const supplyInletHumidityP = Amount.valueAs(Units.PercentHumidity, supplyInletHumidity);
  const extractInletTemperatureC = Amount.valueAs(Units.Celsius, extractInletTemperature);
  const extractInletHumidityP = Amount.valueAs(Units.PercentHumidity, extractInletHumidity);

  const isLautner = Attributes.getString("DLL-input-lautner-code", attributes) !== undefined;
  const isRecutech = Attributes.getString("DLL-input-recutech-code", attributes) !== undefined;
  const isRecair = Attributes.getString("DLL-input-recair-code", attributes) !== undefined;
  const enableWetEfficiency = Attributes.getInt("DLL-enable-wet-temperature-efficiency", attributes) === 1;

  try {
    if (isLautner) {
      const factor = Attributes.getFloatOrDefault("DLL-input-lautner-factor", attributes, 1);
      const rotationSpeed = passiveHouse ? 5 : Attributes.getIntOrThrow("DLL-input-lautner-rotation-speed", attributes);
      const exchangerInput: Lautner.LautnerInput = {
        ConstructionType: Attributes.getStringOrThrow("DLL-input-lautner-construction-type", attributes),
        DriveType: Attributes.getStringOrThrow("DLL-input-lautner-drive-type", attributes),
        RotorSize: Attributes.getIntOrThrow("DLL-input-lautner-rotor-size", attributes),
        RotorType: Attributes.getStringOrThrow("DLL-input-lautner-rotor-type", attributes),
        RotationSpeed: rotationSpeed,

        AirDensity: 1.2,
        AtmosphericPressure: 101325,
        FreshAirFlow: supplyFan.workingPoint.point.x / factor,
        FreshAirTemperature: supplyInletTemperatureC,
        FreshAirRelativeHumidity: supplyInletHumidityP,
        ExtractAirFlow: extractFan.workingPoint.point.x / factor,
        ExtractAirTemperature: extractInletTemperatureC,
        ExtractAirRelativeHumidity: extractInletHumidityP,
      };
      const output = await Lautner.calculate(exchangerInput);
      const efficiencyCorrection = getTemperatureEfficiencyCorrection(
        tempEfficiencyCorrections,
        (supplyFan.workingPoint.point.x * 3.6) / factor
      );
      const maxTemperatureEfficiency = 87;
      const temperatureEfficiency =
        calcTemperatureEfficiency(
          maxTemperatureEfficiency,
          supplyInletTemperatureC,
          output.SupplyAirTemperature,
          extractInletTemperatureC
        ) + efficiencyCorrection;

      const dryEfficiencyEN308 = temperatureEfficiency + 3;

      const humidityEfficiency = output.HumidityEfficiency + efficiencyCorrection;

      const supplyOutletTemperature = calcSupplyOutletTemperature(
        temperatureEfficiency,
        supplyInletTemperatureC,
        extractInletTemperatureC
      );

      const extractOutletTemperature = calcExtractOutletTemperature(
        temperatureEfficiency,
        supplyInletTemperatureC,
        extractInletTemperatureC
      );

      return {
        supplyInletTemperature: supplyInletTemperature,
        supplyInletHumidity: supplyInletHumidity,
        supplyOutletTemperature: Amount.create(supplyOutletTemperature, Units.Celsius, 1),
        supplyOutletHumidity: Amount.create(output.SupplyRelativeHumidity, Units.PercentHumidity, 1),
        supplyPressureDrop: Amount.create(output.SupplyAirPressureDrop, Units.Pascal, 1),

        extractInletTemperature: extractInletTemperature,
        extractInletHumidity: extractInletHumidity,
        extractOutletTemperature: Amount.create(extractOutletTemperature, Units.Celsius, 1),
        extractOutletHumidity: output.ExhaustRelativeHumidity
          ? Amount.create(output.ExhaustRelativeHumidity, Units.PercentHumidity, 1)
          : undefined,
        extractPressureDrop: Amount.create(output.ExhaustAirPressureDrop, Units.Pascal, 1),

        condensate: Amount.create(output.Condensate * factor, Units.LiterPerSecond, 2),
        transferredPower: Amount.create(output.TransferredPowerSensible * factor, Units.KiloWatt, 2),
        dryEfficiencyEN308: undefined,
        temperatureEfficiency: Amount.create(temperatureEfficiency, Units.Percent, 0),
        temperatureEfficiencyUnit: !enableWetEfficiency
          ? Amount.create(temperatureEfficiency, Units.Percent, 0)
          : undefined,
        temperatureDryEfficiencyUnit: enableWetEfficiency
          ? Amount.create(temperatureEfficiency, Units.Percent, 0)
          : undefined,
        temperatureWetEfficiencyUnit: enableWetEfficiency
          ? Amount.create(output.WetEfficiency, Units.Percent, 0)
          : undefined,
        temperatureEfficiencyComponent: Amount.create(dryEfficiencyEN308, Units.Percent, 0),
        humidityEfficiency: Amount.create(humidityEfficiency < 0 ? 0 : humidityEfficiency, Units.Percent, 0),
        latentEfficiencySupply: undefined,

        exchangerType: "rotary",

        messages: [],
      };
    } else if (isRecutech) {
      const factor = Attributes.getFloatOrDefault("DLL-input-recutech-factor", attributes, 1);
      const enthalpyCodes = [40, 41, 42, 45, 44, 46, 47, 49, 52];
      const isEnthalphy = enthalpyCodes.includes(Attributes.getInt("DLL-input-recutech-type", attributes) ?? 0);

      const exchangerInput: Recutech.RecutechInput = {
        Type: Attributes.getIntOrThrow("DLL-input-recutech-type", attributes),
        Width: Attributes.getIntOrThrow("DLL-input-recutech-width", attributes),

        AtmosphericPressure: 101325,
        FreshAirFlow: supplyFan.workingPoint.point.x / factor,
        FreshAirTemperature: supplyInletTemperatureC,
        FreshAirRelativeHumidity: supplyInletHumidityP,
        ExtractAirFlow: extractFan.workingPoint.point.x / factor,
        ExtractAirTemperature: extractInletTemperatureC,
        ExtractAirRelativeHumidity: extractInletHumidityP,
      };
      const output = await Recutech.calculate(exchangerInput);
      if (!output) {
        return undefined;
      }
      const messages: Array<Messages.Message> = [];

      if (output.Messages.find((m) => m.Content === "Condensation") && !isEnthalphy) {
        messages.push(Messages.Warning_Condensation("Exchanger"));
      }

      if (output.Messages.find((m) => m.Content === "Freezing limit")) {
        messages.push(Messages.Warning_DefrostRequired("Exchanger"));
      }

      const temperatureEfficiency = calcTemperatureEfficiency(
        100,
        supplyInletTemperatureC,
        output.SupplyAirTemperature,
        extractInletTemperatureC
      );

      return {
        supplyInletTemperature: supplyInletTemperature,
        supplyInletHumidity: supplyInletHumidity,
        supplyOutletTemperature: Amount.create(output.SupplyAirTemperature, Units.Celsius, 0),
        supplyOutletHumidity: Amount.create(output.SupplyRelativeHumidity, Units.PercentHumidity, 0),
        supplyPressureDrop: Amount.create(output.SupplyAirPressureDrop, Units.Pascal, 0),

        extractInletTemperature: extractInletTemperature,
        extractInletHumidity: extractInletHumidity,
        extractOutletTemperature: Amount.create(output.ExhaustAirTemperature, Units.Celsius, 0),
        extractOutletHumidity: Amount.create(output.ExhaustRelativeHumidity, Units.PercentHumidity, 0),
        extractPressureDrop: Amount.create(output.ExhaustAirPressureDrop, Units.Pascal, 0),

        condensate: Amount.create(output.Condensate * factor, Units.LiterPerMinute, 2),
        transferredPower: Amount.create(output.TransferredPowerSensible * factor, Units.KiloWatt, 2),
        dryEfficiencyEN308: undefined,
        temperatureEfficiency: Amount.create(temperatureEfficiency, Units.Percent, 0),
        temperatureEfficiencyUnit: !enableWetEfficiency
          ? Amount.create(output.DryEfficiency, Units.Percent, 0)
          : undefined,
        temperatureDryEfficiencyUnit: enableWetEfficiency
          ? Amount.create(output.DryEfficiency, Units.Percent, 0)
          : undefined,
        temperatureWetEfficiencyUnit: enableWetEfficiency
          ? Amount.create(output.WetEfficiency, Units.Percent, 0)
          : undefined,
        temperatureEfficiencyComponent: undefined,
        humidityEfficiency: undefined,
        latentEfficiencySupply: isEnthalphy
          ? Amount.create(output.LatentEfficiencySupply * 100, Units.Percent, 0)
          : undefined,

        exchangerType: isEnthalphy ? "enthalpyCounterFlow" : "counterFlow",

        messages: messages,
      };
    } else if (isRecair) {
      const factor = Attributes.getFloatOrDefault("DLL-input-recair-factor", attributes, 1);

      const exchangerInput: Recair.RecairInput = {
        Model: Attributes.getString("DLL-input-recair-model", attributes) ?? "RS160",
        Height: Attributes.getFloatOrThrow("DLL-input-recair-height", attributes),
        FreshAirFlow: supplyFan.workingPoint.point.x / factor,
        FreshAirTemperature: supplyInletTemperatureC,
        FreshAirRelativeHumidity: supplyInletHumidityP,
        ExtractAirFlow: extractFan.workingPoint.point.x / factor,
        ExtractAirTemperature: extractInletTemperatureC,
        ExtractAirRelativeHumidity: extractInletHumidityP,
      };
      const output = await Recair.calculate(exchangerInput);

      const temperatureEfficiency = calcTemperatureEfficiency(
        100,
        supplyInletTemperatureC,
        output.SupplyAirTemperature,
        extractInletTemperatureC
      );

      return {
        supplyInletTemperature: supplyInletTemperature,
        supplyInletHumidity: supplyInletHumidity,
        supplyOutletTemperature: Amount.create(output.SupplyAirTemperature, Units.Celsius, 0),
        supplyOutletHumidity: Amount.create(output.SupplyRelativeHumidity, Units.PercentHumidity, 0),
        supplyPressureDrop: Amount.create(output.SupplyAirPressureDrop, Units.Pascal, 0),

        extractInletTemperature: extractInletTemperature,
        extractInletHumidity: extractInletHumidity,
        extractOutletTemperature: Amount.create(output.ExhaustAirTemperature, Units.Celsius, 0),
        extractOutletHumidity: Amount.create(output.ExhaustRelativeHumidity, Units.PercentHumidity, 0),
        extractPressureDrop: Amount.create(output.ExhaustAirPressureDrop, Units.Pascal, 0),

        condensate: Amount.create(output.Condensate * factor, Units.LiterPerMinute, 2),
        transferredPower: Amount.create(output.TransferredPowerSensible * factor, Units.KiloWatt, 2),
        dryEfficiencyEN308: undefined,
        temperatureEfficiency: Amount.create(temperatureEfficiency, Units.Percent, 0),
        temperatureEfficiencyUnit: !enableWetEfficiency
          ? Amount.create(output.DryEfficiency, Units.Percent, 0)
          : undefined,
        temperatureDryEfficiencyUnit: enableWetEfficiency
          ? Amount.create(output.DryEfficiency, Units.Percent, 0)
          : undefined,
        temperatureWetEfficiencyUnit: enableWetEfficiency
          ? Amount.create(output.WetEfficiency, Units.Percent, 0)
          : undefined,
        temperatureEfficiencyComponent: undefined,
        humidityEfficiency: undefined,
        latentEfficiencySupply: undefined,

        exchangerType: "counterFlow",

        messages: [],
      };
    } else {
      console.warn("Unknown exchanger supplier");
      return undefined;
    }
  } catch (e) {
    console.warn("Error when calling exchanger calc service: ", e);
    return undefined;
  }
}

function getTemperatureEfficiencyCorrection(
  tempEfficiencyCorrection: QP.TempEfficiencyCorrectionTable,
  airFlow: number
): number {
  const exact = tempEfficiencyCorrection.find((t) => t.air_flow === airFlow);
  if (exact) {
    return exact.correction;
  }

  const lower = tempEfficiencyCorrection.filter((c) => c.air_flow < airFlow);
  const higher = tempEfficiencyCorrection.filter((c) => c.air_flow > airFlow);
  if (lower.length === 0 || higher.length === 0) {
    return 0;
  }
  const low = lower.reduce((a, b) => (a.air_flow < b.air_flow ? b : a));
  const high = higher.reduce((a, b) => (a.air_flow > b.air_flow ? b : a));
  const deltaVel = high.air_flow - low.air_flow;
  const deltaCorr = high.correction - low.correction;
  const k = deltaCorr / deltaVel;
  const m = low.correction - k * low.air_flow;
  const corr = k * airFlow + m;
  return corr;
}

function calcSupplyOutletTemperature(
  temperatureEfficiency: number,
  supplyInletTemp: number,
  extractInletTemp: number
): number {
  return supplyInletTemp + (temperatureEfficiency * (extractInletTemp - supplyInletTemp)) / 100;
}

function calcExtractOutletTemperature(
  temperatureEfficiency: number,
  supplyInletTemp: number,
  extractInletTemp: number
): number {
  return extractInletTemp + (temperatureEfficiency * (supplyInletTemp - extractInletTemp)) / 100;
}

function calcTemperatureEfficiency(
  maxTemperatureEfficiency: number,
  supplyInletTemp: number,
  supplyOutletTemp: number,
  extractInletTemp: number
): number {
  const resultTemperatureEfficiency =
    (100 * (supplyOutletTemp - supplyInletTemp)) / (extractInletTemp - supplyInletTemp);
  return Math.min(maxTemperatureEfficiency, resultTemperatureEfficiency);
}
