/* eslint-disable functional/no-let */
// Polyfills
// eslint-disable-next-line import/no-unassigned-import
import "cross-fetch/polyfill";
// Lib imports
import * as React from "react";
import * as ReactDOM from "react-dom";
import { registerFontAwesomeIcons } from "client-lib/font-awesome-icons";
import { SearchProductsContainer } from "containers/search-products";
import { ProductContainer } from "containers/product";
//import { ProductMultipleContainer } from "containers/product-multiple";
import { FricoSpecificationTextEpimContainer } from "containers/frico-specification-text-epim";
import { FricoHeatingCalculationEpimContainer } from "containers/frico-heating-calculation-epim";
import { ErrorHandler, ErrorPage } from "client-lib/error-handler";
import { Provider as ReduxProvider } from "react-redux";
import { clientConfig } from "config";
import { globalSearchReset } from "global-search-reset";
import { PropertyValueSet } from "@promaster-sdk/property";
//import { registerUnits } from "shared-lib/uom-register-units";
import * as C from "shared-lib/calculation";
import * as UserSettingsClient from "client-lib/user-settings";
import * as UserSettingsShared from "shared-lib/user-settings";
import { PersistGate } from "redux-persist/integration/react";
import type { AppInfo } from "shared-lib/version";
import { appInfo } from "shared-lib/version";
import * as QP from "shared-lib/query-product";
import { VersionInfoContainer } from "containers/version-info";
import * as EI from "shared-lib/external-item";
import { initSentry } from "client-lib/sentry";
import { resolveQuery } from "client-lib/redux-query-resolver";
import * as ProductActions from "containers/product/actions";
import { AppContainer } from "./global-styles";
import type { NavigateToItemNo, NavigateToFricoSpecificationText } from "./types";
import { store, persistor } from "./store";
import * as Postmessage from "./postmessage-api";
import * as Theme from "./lib/theme";
import "./style.scss"; // eslint-disable-line import/no-unassigned-import

export * from "./postmessage-api"; //To allow calling postmessage on resultclick

registerFontAwesomeIcons();

window.addEventListener("DOMContentLoaded", (_event) => {
  Postmessage.startPubSubPostMessage(); // Starts the listener for Window.Postmessage
});

function getClientEnvironment(appinfo: AppInfo): string {
  switch (appInfo.clientEnvironment) {
    case "{client_environment}":
      return "localhost";
    case "use_host":
      // eslint-disable-next-line no-restricted-globals
      return location.hostname;
    default:
      return appinfo.clientEnvironment;
  }
}

const sentryDSN = "https://b5e788d4b57a4ecb97a50f86f0e4cd3d@o4504474598375424.ingest.sentry.io/6189918";
initSentry(sentryDSN, appInfo.pipelineId, getClientEnvironment(appInfo));

interface SearchProps {
  readonly ecomUrl: string;
  readonly market: string;
  readonly language: string;
  readonly searchProductKey: string;
  readonly navigateToItemNo: NavigateToItemNo;
  readonly externalConfig: EI.ExternalConfig | undefined;
  readonly showPrice: boolean;
  readonly specificationDataUrl?: string;
  readonly navigateToSpecificationText: NavigateToFricoSpecificationText;
  readonly shopUrl?: string;
  readonly fricoSpecificationTextUrl?: string;
}

interface FricoProps {
  readonly ecomUrl: string;
  readonly market: string;
  readonly language: string;
  readonly specificationDataUrl: string;
}

interface CalculationProps {
  readonly ecomUrl: string;
  readonly market: string;
  readonly language: string;
  readonly m3ItemNo: string;
  readonly externalConfig: EI.ExternalConfig | undefined;
  readonly variant: string | undefined;
  readonly hideName?: boolean;
  readonly config: string | undefined;
  readonly shopUrl?: string;
  readonly navigateToItemNo?: NavigateToItemNo;
  readonly dontSendReadyEvent?: boolean;
}
let currentCalculationProps: CalculationProps | undefined = undefined;
let currentFricoProps: FricoProps | undefined = undefined;
let currentSearchProps: SearchProps | undefined = undefined;

export function getCurrentCalculationProps(): CalculationProps | undefined {
  return currentCalculationProps;
}

function padMarket(market: string): string {
  if (market.length === 1) {
    return "00" + market;
  } else if (market.length === 2) {
    return "0" + market;
  } else {
    return market;
  }
}

function getLocation(): string {
  const url = window.location !== window.parent.location ? document.referrer : window.location.href.toLowerCase();
  return url;
}

function onFricoWebsite(): boolean {
  return getLocation().toLowerCase().indexOf("frico") > -1 || window.location.href.toLowerCase().indexOf("frico") > -1;
}

function onFantechWebsite(): boolean {
  return (
    getLocation().toLowerCase().indexOf("fantech") > -1 || window.location.href.toLowerCase().indexOf("fantech") > -1
  );
}

function Search(props: SearchProps): React.ReactElement<SearchProps> {
  const fixedProps = { ...props, market: padMarket(props.market) };
  currentSearchProps = fixedProps;
  const searchProductId = QP.searchProducts[props.searchProductKey] || props.searchProductKey;
  const fricoWebsite = onFricoWebsite();
  if (fricoWebsite) {
    Theme.applyThemeColors("frico");
  } else if (onFantechWebsite()) {
    Theme.applyThemeColors("fantech");
  } else {
    Theme.applyThemeColors("systemair");
  }
  return (
    <ErrorHandler store={store} errorPage={ErrorPage}>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <AppContainer frico={fricoWebsite}>
            <VersionInfoContainer itemInfo={undefined} />
            <SearchProductsContainer {...fixedProps} isFricoWebsite={fricoWebsite} searchProductId={searchProductId} />
          </AppContainer>
        </PersistGate>
      </ReduxProvider>
    </ErrorHandler>
  );
}

export const startSearch = async (props: SearchProps, elementId: string): Promise<void> => {
  globalSearchReset.language = props.language;
  globalSearchReset.market = padMarket(props.market);

  ReactDOM.render(<Search {...props} />, getAppContainerElement(elementId));
};

function Calculation(props: CalculationProps): React.ReactElement<CalculationProps> {
  const fixedProps = {
    ...props,
    market: padMarket(props.market),
    language: props.language === undefined ? "en" : props.language,
  };
  currentCalculationProps = fixedProps;
  const fricoWebsite = onFricoWebsite();
  if (fricoWebsite) {
    Theme.applyThemeColors("frico");
  } else if (onFantechWebsite()) {
    Theme.applyThemeColors("fantech");
  } else {
    Theme.applyThemeColors("systemair");
  }

  // In the UI we remove the properties to ensure we use the supplied m3itemno and variant
  // We do this to allow DMS switching variant in a PDF popup without having to load the selection tool
  const configWithoutProperties = props.config
    ? C.deserializeConfig(props.config, "ignore-properties")
    : C.emptyItemConfig;

  return (
    <ErrorHandler store={store} errorPage={ErrorPage}>
      <ReduxProvider store={store}>
        <AppContainer frico={fricoWebsite}>
          <div className={clientConfig.addOuterPadding ? "px-400" : ""}>
            <ProductContainer {...fixedProps} hideInvalidValues={!fricoWebsite} config={configWithoutProperties} />
          </div>
        </AppContainer>
      </ReduxProvider>
    </ErrorHandler>
  );
}

export const startCalculation = (props: CalculationProps, elementId: string | undefined): void =>
  ReactDOM.render(<Calculation {...props} />, getAppContainerElement(elementId));

export function NavigateToSpecificationText(): void {
  if (currentSearchProps !== undefined) {
    if (!currentSearchProps.specificationDataUrl) {
      throw new Error("specificationDataUrl parameter can not be empty when running Frico");
    }

    const props = {
      ecomUrl: currentSearchProps.ecomUrl,
      market: currentSearchProps.market,
      language: currentSearchProps.language,
      specificationDataUrl: currentSearchProps.specificationDataUrl,
    };
    startFricoSpecificationText(props, "app-container");
  } else if (currentFricoProps !== undefined) {
    const props = {
      ecomUrl: currentFricoProps.ecomUrl,
      market: currentFricoProps.market,
      language: currentFricoProps.language,
      specificationDataUrl: currentFricoProps.specificationDataUrl,
    };
    startFricoSpecificationText(props, "app-container");
  } else {
    console.warn("ERROR");
  }
}

function FricoSpecificationText(props: FricoProps): React.ReactElement<FricoProps> {
  const fixedProps = { ...props, market: padMarket(props.market) };
  currentFricoProps = fixedProps;
  Theme.applyThemeColors("frico");
  return (
    <ErrorHandler store={store} errorPage={ErrorPage}>
      <ReduxProvider store={store}>
        <AppContainer frico={true}>
          <VersionInfoContainer itemInfo={undefined} />
          <FricoSpecificationTextEpimContainer {...fixedProps} />
        </AppContainer>
      </ReduxProvider>
    </ErrorHandler>
  );
}

export function startFricoSpecificationText(props: FricoProps, elementId: string): void {
  const fixedProps = { ...props, market: padMarket(props.market) };
  ReactDOM.render(<FricoSpecificationText {...fixedProps} />, getAppContainerElement(elementId));
}

function FricoHeatingCalculation(props: FricoProps): React.ReactElement<CalculationProps> {
  const fixedProps = { ...props, market: padMarket(props.market) };
  currentFricoProps = fixedProps;
  Theme.applyThemeColors("frico");
  return (
    <ErrorHandler store={store} errorPage={ErrorPage}>
      <ReduxProvider store={store}>
        <AppContainer frico={true}>
          <VersionInfoContainer itemInfo={undefined} />
          <FricoHeatingCalculationEpimContainer {...fixedProps} />
        </AppContainer>
      </ReduxProvider>
    </ErrorHandler>
  );
}

export function startFricoHeatingCalculation(props: FricoProps, elementId: string): void {
  const fixedProps = { ...props, market: padMarket(props.market) };
  ReactDOM.render(<FricoHeatingCalculation {...fixedProps} />, getAppContainerElement(elementId));
}

export function getPrintItems(): Promise<EI.ExternalResult> {
  if (!currentCalculationProps) {
    throw new Error("Not in calculation");
  }
  const state = store.getState();
  const userSettings = UserSettingsClient.stateSelector(state);

  const ownProps: EI.OwnProps = {
    market: currentCalculationProps.market,
    language: currentCalculationProps.language,
    m3ItemNo: currentCalculationProps.m3ItemNo,
    variant: currentCalculationProps.variant,
    propsConfig: currentCalculationProps.config
      ? C.deserializeConfig(currentCalculationProps.config, "use-properties")
      : undefined,
    stateConfig: state.ui.product.config,
    userSettings: userSettings,
    imageServiceUrl: clientConfig.imageServiceUrl,
    externalConfig: currentCalculationProps.externalConfig,
  };

  // eslint-disable-next-line no-restricted-globals
  return EI.createExternalItem(ownProps, resolveQuery);
}

export async function getConfig(): Promise<string | undefined> {
  if (!currentCalculationProps) {
    throw new Error("Not in calculation");
  }
  const state = store.getState();
  const userSettings = UserSettingsClient.stateSelector(state);

  const ownProps: EI.OwnProps = {
    market: currentCalculationProps.market,
    language: currentCalculationProps.language,
    m3ItemNo: currentCalculationProps.m3ItemNo,
    variant: currentCalculationProps.variant,
    propsConfig: currentCalculationProps.config
      ? C.deserializeConfig(currentCalculationProps.config, "use-properties")
      : undefined,
    stateConfig: state.ui.product.config,
    userSettings: userSettings,
    imageServiceUrl: clientConfig.imageServiceUrl,
    externalConfig: currentCalculationProps.externalConfig,
  };
  return EI.createExternalConfig(ownProps, resolveQuery);
}

export function setItem(itemNo: string, variant: string | undefined, serializedConfig: string | undefined): void {
  if (!currentCalculationProps) {
    throw new Error("Not in calculation");
  }

  // Update redux state
  let state = store.getState();
  if (serializedConfig) {
    const config = C.deserializeConfig(serializedConfig, "ignore-properties");
    const newConfig = { ...config, properties: PropertyValueSet.Empty, meta: undefined, accessories: [] };
    const newFieldUnits = {
      ...UserSettingsShared.getFieldUnitSettings({
        userSettings: state.settings.user,
      }),
      ...(config.meta?.fieldUnits || {}),
    };
    store.dispatch(UserSettingsClient.setFieldUnitConfig(newFieldUnits));
    store.dispatch(ProductActions.setConfig(newConfig));
  } else {
    const newConfig = { ...state.ui.product.config, properties: PropertyValueSet.Empty, accessories: [] };
    store.dispatch(ProductActions.setConfig(newConfig));
  }
  state = store.getState();

  // Update props
  const newConfigWithMeta: C.ItemConfigWithMeta = {
    ...state.ui.product.config,
    meta: {
      ...state.settings.user,
    },
  };
  const serializedNewConfig = C.serializeConfig(newConfigWithMeta);
  currentCalculationProps = {
    ...currentCalculationProps,
    m3ItemNo: itemNo,
    variant: variant,
    config: serializedNewConfig,
    dontSendReadyEvent: !!serializedConfig,
  };

  startCalculation(currentCalculationProps, undefined);
}

let lastAppContainerElement: HTMLElement | null = null;
function getAppContainerElement(elementId: string | undefined): HTMLElement | null {
  if (elementId === undefined) {
    return lastAppContainerElement;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const css: string | undefined = (window as any).ganymedAppInlinedCss;
  if (css) {
    const appContainer = document.getElementById(elementId);
    if (!appContainer) {
      return null;
    }
    appContainer.innerHTML = "";

    const htmlContainer = document.createElement("div");
    htmlContainer.id = "ganymed-app-html";
    appContainer.appendChild(htmlContainer);

    const bodyContainer = document.createElement("div");
    bodyContainer.id = "ganymed-app-body";
    htmlContainer.appendChild(bodyContainer);

    const st = document.createElement("style");
    st.innerHTML = css;
    bodyContainer.appendChild(st);

    const innerAppContainer = document.createElement("div");
    bodyContainer.appendChild(innerAppContainer);

    lastAppContainerElement = innerAppContainer;
  } else {
    lastAppContainerElement = document.getElementById(elementId);
  }

  return lastAppContainerElement;
}
