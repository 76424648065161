import type { Amount, Unit } from "uom";
import type { Quantity } from "uom-units";
import type { AnyQuantity } from "shared-lib/uom";
import type * as Interpolation from "shared-lib/interpolation";
import type * as Messages from "./messages";
import type { BoxFanCurve } from "./box-fan/types";

export interface ResultItemMap {
  readonly [name: string]: ResultItem;
}

export type ResultItem =
  | ElectricDuctHeaterResultItem
  | WaterCoilResultItem
  | DxCoilResultItem
  | HeatRecoveryUnitResultItem
  | SystemairHeatRecoveryUnitResultItem
  | FanResultItem
  | AirCurtainResultItem
  | SilencerResultItem
  | FilterResultItem
  | OtherResultItem
  | BoxFanResultItem
  | BoxFanNicotraResultItem
  | CentrifugalFanResultItem
  | BoxFanYilidaResultItem;

export interface Curve {
  readonly id: string;
  readonly controlVoltage: number;
  readonly supplyVoltage: number;
  readonly controlFrequency: number;
  readonly label?: string | undefined;
  readonly unitX: Unit.Unit<AnyQuantity>;
  readonly unitY: Unit.Unit<AnyQuantity>;
  readonly workMin: number;
  readonly workMax: number;
  readonly spline: Interpolation.Spline;
}

export interface OctaveBands {
  readonly type: "Octave";
  readonly hz63: number | undefined;
  readonly hz125: number | undefined;
  readonly hz250: number | undefined;
  readonly hz500: number | undefined;
  readonly hz1000: number | undefined;
  readonly hz2000: number | undefined;
  readonly hz4000: number | undefined;
  readonly hz8000: number | undefined;
  readonly total: Amount.Amount<Quantity.SoundPowerLevel> | undefined;
}

export interface OctaveBands3rd {
  readonly type: "Octave3rd";
  readonly hz50: number | undefined;
  readonly hz63: number | undefined;
  readonly hz80: number | undefined;
  readonly hz100: number | undefined;
  readonly hz125: number | undefined;
  readonly hz160: number | undefined;
  readonly hz200: number | undefined;
  readonly hz250: number | undefined;
  readonly hz315: number | undefined;
  readonly hz400: number | undefined;
  readonly hz500: number | undefined;
  readonly hz630: number | undefined;
  readonly hz800: number | undefined;
  readonly hz1000: number | undefined;
  readonly hz1250: number | undefined;
  readonly hz1600: number | undefined;
  readonly hz2000: number | undefined;
  readonly hz2500: number | undefined;
  readonly hz3150: number | undefined;
  readonly hz4000: number | undefined;
  readonly hz5000: number | undefined;
  readonly hz6300: number | undefined;
  readonly hz8000: number | undefined;
  readonly hz10000: number | undefined;
  readonly total: Amount.Amount<Quantity.SoundPowerLevel> | undefined;
}

export interface ElectricDuctHeaterResultItem {
  readonly type: "ElectricDuctHeater";
  readonly value: ElectricDuctHeater;
}

export interface ElectricDuctHeater {
  readonly maxPower: Amount.Amount<Quantity.Power>;
  readonly minAirFlow?: Amount.Amount<Quantity.VolumeFlow>;
  readonly maxAirFlow?: Amount.Amount<Quantity.VolumeFlow>;
  readonly maxOutletTemperature?: Amount.Amount<Quantity.Temperature>;
  readonly voltage: Amount.Amount<Quantity.ElectricPotential>;
  readonly phases: Amount.Amount<Quantity.Dimensionless>;
  readonly current: Amount.Amount<Quantity.ElectricCurrent>;

  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly powerPercent: Amount.Amount<Quantity.Dimensionless>;
  readonly missingPower: Amount.Amount<Quantity.Power>;
  readonly inletAirTemperature: Amount.Amount<Quantity.Temperature>;
  readonly inletAirHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  readonly outletAirTemperature: Amount.Amount<Quantity.Temperature>;
  readonly outletAirHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  readonly pressureDrop?: Amount.Amount<Quantity.Pressure>;

  readonly pressureDropCurve?: Curve;

  readonly messages: ReadonlyArray<Messages.Message>;
}

export interface AirCurtainResultItem {
  readonly type: "AirCurtain";
  readonly value: AirCurtain;
}

export interface AirCurtain {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly manueverVoltage: Amount.Amount<Quantity.ElectricPotential>;
  readonly airPressureDrop: Amount.Amount<Quantity.Pressure> | undefined;
  readonly waterPressureDrop: Amount.Amount<Quantity.Pressure> | undefined;
  readonly outletAirTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly outletAirHumidity: Amount.Amount<Quantity.RelativeHumidity> | undefined;
  readonly inletAirHumidity: Amount.Amount<Quantity.RelativeHumidity> | undefined;
  readonly waterFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly inletWaterTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly inletAirTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly outletWaterTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly power: Amount.Amount<Quantity.Power> | undefined;
  readonly glycol: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly shf: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly condensedWater: Amount.Amount<Quantity.MassFlow> | undefined;
  readonly fluidSpecificHeat: Amount.Amount<Quantity.SpecificHeatCapacity> | undefined;
  readonly fluid: string | undefined;
  readonly control: string | undefined;
  readonly version: string | undefined;
  readonly coilCode: string | undefined;
}

export function createAirCurtainResultItem(airCurtain: AirCurtain): AirCurtainResultItem {
  return {
    type: "AirCurtain",
    value: airCurtain,
  };
}

export function createElectricDuctHeaterResultItem(
  electricDuctHeater: ElectricDuctHeater
): ElectricDuctHeaterResultItem {
  return {
    type: "ElectricDuctHeater",
    value: electricDuctHeater,
  };
}

export interface WaterCoilResultItem {
  readonly type: "WaterCoil";
  readonly value: WaterCoil;
}

export interface WaterCoil {
  readonly waterPressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly airVelocity: Amount.Amount<Quantity.Velocity>;
  readonly waterVelocity: Amount.Amount<Quantity.Velocity>;
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly airPressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly recomendedKv: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly outletAirTemperature: Amount.Amount<Quantity.Temperature>;
  readonly outletAirHumidity: Amount.Amount<Quantity.RelativeHumidity> | undefined;
  readonly waterFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly inletWaterTemperature: Amount.Amount<Quantity.Temperature>;
  readonly outletWaterTemperature: Amount.Amount<Quantity.Temperature>;
  readonly power: Amount.Amount<Quantity.Power>;
  readonly inletAirTemperature: Amount.Amount<Quantity.Temperature>;
  readonly inletAirHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  readonly connectionSizeIn: string | undefined;
  readonly connectionSizeOut: string | undefined;
  readonly coilCode: string | undefined;

  readonly pressureDropCurve: Curve | undefined;
}

export function createWaterCoilResultItem(waterCoil: WaterCoil): WaterCoilResultItem {
  return {
    type: "WaterCoil",
    value: waterCoil,
  };
}

export interface DxCoilResultItem {
  readonly type: "DxCoil";
  readonly value: DxCoil;
}

export interface DxCoil {
  readonly airVelocity: Amount.Amount<Quantity.Velocity>;
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly airPressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly outletAirTemperature: Amount.Amount<Quantity.Temperature>;
  readonly outletAirHumidity: Amount.Amount<Quantity.RelativeHumidity> | undefined;
  readonly evaporatingTemperature: Amount.Amount<Quantity.Temperature>;
  readonly condensingTemperature: Amount.Amount<Quantity.Temperature>;
  readonly power: Amount.Amount<Quantity.Power>;
  readonly inletAirTemperature: Amount.Amount<Quantity.Temperature>;
  readonly inletAirHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  readonly connectionSizeIn: string | undefined;
  readonly connectionSizeOut: string | undefined;
  readonly coilCode: string | undefined;
  readonly pressureDropCurve: Curve | undefined;
  readonly refrigerantType: string | undefined;
  readonly refrigerantPressureDrop: Amount.Amount<Quantity.Pressure> | undefined;
  readonly refrigerantMassFlow: Amount.Amount<Quantity.MassFlow> | undefined;
}

export function createDxCoilResultItem(dxCoil: DxCoil): DxCoilResultItem {
  return {
    type: "DxCoil",
    value: dxCoil,
  };
}

export interface HeatRecoveryUnitResultItem {
  readonly type: "HeatRecoveryUnit";
  readonly value: HeatRecoveryUnit;
}

export interface SystemairHeatRecoveryUnitResultItem {
  readonly type: "SystemairHeatRecoveryUnit";
  readonly value: SystemairHeatRecoveryUnit;
}

export interface HeatRecoveryUnit {
  readonly supplyFan: FanAirResult;
  readonly extractFan: FanAirResult;
  readonly exchanger: ExchangerResult | undefined;
  readonly electricHeater: ElectricDuctHeater | undefined;
  readonly totalSfp: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly supplyOutletTemperature: Amount.Amount<Quantity.Temperature> | undefined;
  readonly airDensity: Amount.Amount<Quantity.Density> | undefined;
  readonly supplySound: OctaveBands3rd | undefined;
  readonly outdoorSound: OctaveBands3rd | undefined;
  readonly extractSound: OctaveBands3rd | undefined;
  readonly exhaustSound: OctaveBands3rd | undefined;
  readonly surroundingSound: OctaveBands3rd | undefined;
}

export interface SystemairHeatRecoveryUnit {
  readonly supplyFan: FanAirResult;
  readonly extractFan: FanAirResult;
  readonly exchangerSummer: ExchangerResult | undefined;
  readonly exchangerWinter: ExchangerResult | undefined;
  readonly electricHeaterSummer: ElectricDuctHeater | undefined;
  readonly electricHeaterWinter: ElectricDuctHeater | undefined;
  readonly totalSfp: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly supplyOutletTemperatureSummer: Amount.Amount<Quantity.Temperature> | undefined;
  readonly supplyOutletTemperatureWinter: Amount.Amount<Quantity.Temperature> | undefined;
  readonly airDensity: Amount.Amount<Quantity.Density> | undefined;
  readonly supplySound: OctaveBands3rd | undefined;
  readonly outdoorSound: OctaveBands3rd | undefined;
  readonly extractSound: OctaveBands3rd | undefined;
  readonly exhaustSound: OctaveBands3rd | undefined;
  readonly surroundingSound: OctaveBands3rd | undefined;
}

export interface ExchangerResult {
  readonly supplyInletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly supplyOutletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly supplyInletHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  readonly supplyOutletHumidity: Amount.Amount<Quantity.RelativeHumidity> | undefined;
  readonly supplyPressureDrop: Amount.Amount<Quantity.Pressure>;

  readonly extractInletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly extractInletHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  readonly extractOutletTemperature: Amount.Amount<Quantity.Temperature>;
  readonly extractOutletHumidity: Amount.Amount<Quantity.RelativeHumidity> | undefined;
  readonly extractPressureDrop: Amount.Amount<Quantity.Pressure>;

  readonly condensate: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly transferredPower: Amount.Amount<Quantity.Power>;
  readonly dryEfficiencyEN308: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly temperatureEfficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly temperatureEfficiencyUnit: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly temperatureDryEfficiencyUnit: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly temperatureWetEfficiencyUnit: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly temperatureEfficiencyComponent: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly humidityEfficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly latentEfficiencySupply: Amount.Amount<Quantity.Dimensionless> | undefined;

  readonly exchangerType: string;
  readonly messages: ReadonlyArray<Messages.Message>;
}

export function createHeatRecoveryUnitResultItem(heatRecoveryUnit: HeatRecoveryUnit): HeatRecoveryUnitResultItem {
  return {
    type: "HeatRecoveryUnit",
    value: heatRecoveryUnit,
  };
}

export function createSystemairHeatRecoveryUnitResultItem(
  heatRecoveryUnit: SystemairHeatRecoveryUnit
): SystemairHeatRecoveryUnitResultItem {
  return {
    type: "SystemairHeatRecoveryUnit",
    value: heatRecoveryUnit,
  };
}

export interface BoxFanResultItem {
  readonly type: "BoxFan";
  readonly value: BoxFan;
}

export interface CentrifugalFanResultItem {
  readonly type: "CentrifugalFan";
  readonly value: CentrifugalFan;
}

export interface BoxFan {
  readonly air: BoxFanAirResult;
  readonly outletSound: OctaveBands | undefined;
  readonly surroundingSound: OctaveBands | undefined;
  readonly soundPressureLevelLpa: Amount.Amount<Quantity.SoundPressureLevel> | undefined;
  readonly soundPressureDistance: Amount.Amount<Quantity.Length> | undefined;
  readonly erpFanData: ErpFanData | undefined;
  readonly ecoDesignData: EcoDesignFanData | undefined;
}

export interface MotorResult {
  readonly power: Amount.Amount<Quantity.Power>;
  readonly current: Amount.Amount<Quantity.ElectricCurrent>;
  readonly startingCurrent: Amount.Amount<Quantity.ElectricCurrent>;
  readonly weight: number;
  readonly efficiency: Amount.Amount<Quantity.Dimensionless>;
  readonly performanceFactorCosPhi: Amount.Amount<Quantity.Dimensionless>;
  readonly efficiencyClass: string;
  readonly pole: Amount.Amount<Quantity.Dimensionless>;
}

export interface CentrifugalFan {
  readonly air: FanAirResult;
  readonly airVelocity: Amount.Amount<Quantity.Velocity> | undefined;
  readonly fanEfficiencyIndex: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly frequency: Amount.Amount<Quantity.Frequency> | undefined;
  readonly voltage: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly motor: MotorResult | undefined;
  readonly totalWeight: Amount.Amount<Quantity.Mass> | undefined;
  readonly inletSound: OctaveBands3rd | undefined;
  readonly outletSound: OctaveBands3rd | undefined;
  readonly surroundingSound: OctaveBands3rd | undefined;
  readonly soundPressureLevelAt3m: Amount.Amount<Quantity.SoundPressureLevel> | undefined;
  readonly powerCurves: ReadonlyArray<Curve> | undefined;
  readonly classCurves: ReadonlyArray<Curve> | undefined;
  readonly totalEfficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly totalPressure: Amount.Amount<Quantity.Pressure> | undefined;
}

export interface ErpFanData {
  readonly efficiencyGradeNReal: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly efficiencyGradeNNorm: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly objectiveN: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly realN: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly measurementCategory: string | undefined;
  readonly efficiencyCategory: string;
  readonly specificRation: Amount.Amount<Quantity.Dimensionless>;
}

export interface EcoDesignFanData {
  readonly manufacturer: string | undefined;
  readonly productName: string | undefined;
  readonly erpClassification: string | undefined;
  readonly unitCategory: string | undefined;
  readonly drive: string | undefined;
  readonly unitType: string | undefined;
  readonly heatRecoveryType: string | undefined;
  readonly temperatureRatio: string | undefined;
  readonly workingAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly effectiveElectricPowerInputInclVariableDrive: Amount.Amount<Quantity.Power> | undefined;
  readonly sfp: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly nominalExternalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly efficiencyByStaticPressure: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly externalLeakage: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly soundPower: Amount.Amount<Quantity.SoundPowerLevel> | undefined;
}

export interface BoxFanNicotraResultItem {
  readonly type: "BoxFanNicotra";
  readonly value: BoxFanNicotra;
}

export interface NicotraAir {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly externalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly totalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly efficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly fanSpeed: Amount.Amount<Quantity.Frequency> | undefined;
  readonly airVelocity: Amount.Amount<Quantity.Velocity> | undefined;
  readonly fanShaftPower: Amount.Amount<Quantity.Power> | undefined;
  readonly absorbedPower: Amount.Amount<Quantity.Power> | undefined;
  readonly airDensity: Amount.Amount<Quantity.Density> | undefined;
}

export interface BoxFanNicotra {
  readonly product: NicotraProduct;
  readonly air: NicotraAir;
  readonly accessories: ReadonlyArray<string> | undefined;
  readonly diagramBase64: string | undefined;

  readonly soundPressureDistance: Amount.Amount<Quantity.Length> | undefined;
  readonly fanInlet: OctaveBands | undefined;
  readonly fanOutlet: OctaveBands | undefined;
  readonly unitBreakout: OctaveBands | undefined;
  readonly soundPressureLevel: OctaveBands | undefined;
}

export interface NicotraProduct {
  readonly family: string;
  readonly size: string;
  readonly motor: string;
  readonly motorPower: Amount.Amount<Quantity.Power> | undefined;
  readonly motorVoltage: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly motorCurrent: Amount.Amount<Quantity.ElectricCurrent> | undefined;
}

export interface BoxFanAirResult {
  readonly minAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly maxAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly pressureOutlineCurves: ReadonlyArray<BoxFanCurve>;
  readonly rpmCurves: ReadonlyArray<BoxFanCurve>;
  readonly efficiencyCurves: ReadonlyArray<BoxFanCurve>;
  readonly powerCurves: ReadonlyArray<BoxFanCurve>;
  readonly powerOutlineCurves: ReadonlyArray<BoxFanCurve>;
  readonly actualRpmCurve: BoxFanCurve | undefined;
  readonly maxPowerDemand: Amount.Amount<Quantity.Power> | undefined;
  readonly fanId: string;
  readonly bestStandardFan?: string;
  readonly motorId: string | undefined;
  readonly frequency: number | undefined;
  readonly insideWorkingArea: boolean;

  readonly desiredAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly desiredExternalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly airDensity: Amount.Amount<Quantity.Density> | undefined;
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly externalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly totalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly power: Amount.Amount<Quantity.Power> | undefined;
  readonly efficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly fanSpeed: Amount.Amount<Quantity.Frequency> | undefined;
  readonly airVelocity: Amount.Amount<Quantity.Velocity> | undefined;

  readonly family: string;
  readonly size: string;
  readonly motorPower: Amount.Amount<Quantity.Power> | undefined;
  readonly motorVoltage: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly accessories: ReadonlyArray<string> | undefined;
  readonly fanIsConfigurable: boolean;
}

export interface BoxFanYilidaResultItem {
  readonly type: "BoxFanYilida";
  readonly value: BoxFanYilida;
}

export interface YilidaAir {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly externalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly totalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly airDensity: Amount.Amount<Quantity.Density> | undefined;
  readonly efficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly fanSpeed: Amount.Amount<Quantity.Frequency> | undefined;
  readonly airVelocity: Amount.Amount<Quantity.Velocity> | undefined;
  readonly absorbedPower: Amount.Amount<Quantity.Power> | undefined;
}

export interface YilidaProduct {
  readonly family: string;
  readonly size: string;
  readonly motor: string;
  readonly motorPower: Amount.Amount<Quantity.Power> | undefined;
  readonly motorVoltage: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly motorPhase: Amount.Amount<Quantity.Dimensionless> | undefined;
}

export interface BoxFanYilida {
  readonly air: YilidaAir;
  readonly product: YilidaProduct;
  readonly accessories: ReadonlyArray<string> | undefined;
  readonly diagramBase64: string | undefined;
  readonly soundPressureDistance: Amount.Amount<Quantity.Length> | undefined;
  readonly soundPowerOutlet: OctaveBands | undefined;
  readonly soundPressure: OctaveBands | undefined;
}

export function createBoxFanResultItem(fan: BoxFan): BoxFanResultItem {
  return {
    type: "BoxFan",
    value: fan,
  };
}

export function createCentrifugalFanResultItem(fan: CentrifugalFan): CentrifugalFanResultItem {
  return {
    type: "CentrifugalFan",
    value: fan,
  };
}

export function createBoxFanNicotraResultItem(fan: BoxFanNicotra): BoxFanNicotraResultItem {
  return {
    type: "BoxFanNicotra",
    value: fan,
  };
}

export function createBoxFanYilidaResultItem(fan: BoxFanYilida): BoxFanYilidaResultItem {
  return {
    type: "BoxFanYilida",
    value: fan,
  };
}

export interface FanResultItem {
  readonly type: "Fan";
  readonly value: Fan;
}

export interface Fan {
  readonly air: FanAirResult;
  readonly inletSound: OctaveBands3rd | undefined;
  readonly inletWithSilencerSound: OctaveBands | undefined;
  readonly outletSound: OctaveBands3rd | undefined;
  readonly surroundingSound: OctaveBands3rd | undefined;
  readonly outletWithSilencerSound: OctaveBands | undefined;
  readonly nominal: FanNominalData;
  readonly accessories: ReadonlyArray<AccessoryResult>;
  readonly fanEfficiencyIndex: Amount.Amount<Quantity.Dimensionless> | undefined;
}

export interface AccessoryResult {
  readonly id: string;
  readonly pressureDrop: Amount.Amount<Quantity.Pressure> | undefined;
}

export interface FanAirResult {
  readonly diff: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly distanceWorkingPointToDesiredPoint: number | undefined;
  readonly distanceWorkingPointToMaxPoint: number | undefined;
  readonly desiredPointIsOutsideValidArea: boolean | undefined;
  readonly diagramDrawMethod: "Default" | "AMCA";

  readonly desiredAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly desiredExternalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly airDensity: Amount.Amount<Quantity.Density> | undefined;

  readonly pressureCurves: ReadonlyArray<Curve>;
  readonly adjustedPressureCurves: ReadonlyArray<Curve>;
  readonly powerCurves: ReadonlyArray<Curve>;
  readonly shaftPowerCurves: ReadonlyArray<Curve>;
  readonly currentCurves: ReadonlyArray<Curve>;
  readonly rpmCurves: ReadonlyArray<Curve>;
  readonly feiCurves: ReadonlyArray<Curve> | undefined;

  readonly speedControl: SpeedControl;
  readonly workingPoint: WorkingPoint | undefined;
  readonly systemCurveMinValidPoint: ValidSystemCurvePoint | undefined;
  readonly systemCurveMaxValidPoint: ValidSystemCurvePoint | undefined;
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly externalPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly controlVoltage: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly controlVoltagePercent: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly supplyVoltage: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly supplyVoltagePercent: Amount.Amount<Quantity.Dimensionless> | undefined;

  readonly frequencyControlHertz: Amount.Amount<Quantity.Frequency> | undefined;
  readonly frequencyControlPercent: Amount.Amount<Quantity.Dimensionless> | undefined;

  readonly shaftPower: Amount.Amount<Quantity.Power> | undefined;
  readonly power: Amount.Amount<Quantity.Power> | undefined;
  readonly current: Amount.Amount<Quantity.ElectricCurrent> | undefined;
  readonly fanSpeed: Amount.Amount<Quantity.Frequency> | undefined;
  readonly sfp: Amount.Amount<Quantity.SpecificFanPower> | undefined;
  readonly efficiency: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly minAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly maxAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly minPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly maxPressure: Amount.Amount<Quantity.Pressure> | undefined;
  readonly airflowEfficiency: Amount.Amount<Quantity.VolumeFlowPerPower> | undefined;
  readonly efficiencyShaftPower: Amount.Amount<Quantity.Dimensionless> | undefined;

  readonly voltageLow: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly voltageHigh: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly voltagePercentLow: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly voltagePercentHigh: Amount.Amount<Quantity.Dimensionless> | undefined;
  readonly fanSpeedLow: Amount.Amount<Quantity.Frequency> | undefined;
  readonly fanSpeedHigh: Amount.Amount<Quantity.Frequency> | undefined;
}

export interface ValidSystemCurvePoint {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressure: Amount.Amount<Quantity.Pressure>;
}

export interface FanNominalData {
  readonly voltage: Amount.Amount<Quantity.ElectricPotential> | undefined;
  readonly power: Amount.Amount<Quantity.Power> | undefined;
  readonly current: Amount.Amount<Quantity.ElectricCurrent> | undefined;
  readonly speed: Amount.Amount<Quantity.Frequency> | undefined;
  readonly maxAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined;
  readonly soundPressure: Amount.Amount<Quantity.SoundPressureLevel> | undefined;
  readonly insulationClass: string | undefined;
  readonly enclosureClass: string | undefined;
  readonly weight: Amount.Amount<Quantity.Mass> | undefined;
}

export interface WorkingPoint {
  readonly point: Interpolation.Vec2;
  readonly controlVoltage: number;
  readonly supplyVoltage: number;
  readonly controlFrequency: number;
  readonly curveId?: string;
  readonly curveIndex?: number;
  readonly solution: Solution;
}

export type Solution = SolutionCurve | SolutionInterpolated;

export interface SolutionCurve {
  readonly type: "Curve";
  readonly curveId: string;
  readonly point: Interpolation.Vec2;
  readonly ratio: number;
}

export interface SolutionInterpolated {
  readonly type: "Interpolated";
  readonly lower: Solution;
  readonly higher: Solution;
  readonly point: Interpolation.Vec2;
  readonly ratio: number;
}

export interface SoundResult {
  readonly octaveBands?: OctaveBands;
  readonly octaveBandsA?: OctaveBands;
  readonly octaveBands3rd?: OctaveBands3rd;
  readonly octaveBands3rdA?: OctaveBands3rd;
}

export type SpeedControl = "None" | "Stepless" | "Transformer" | "Frequency converter";

export function createFanResultItem(fan: Fan): FanResultItem {
  return {
    type: "Fan",
    value: fan,
  };
}

export interface SilencerResultItem {
  readonly type: "Silencer";
  readonly value: Silencer;
}

export function createSilencerResultItem(silencer: Silencer): SilencerResultItem {
  return {
    type: "Silencer",
    value: silencer,
  };
}

export interface Silencer {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly soundAttenuation: OctaveBands;
  readonly silencerType: string | undefined;
  readonly pressureDropCurve: Curve;
}

export interface FilterResultItem {
  readonly type: "Filter";
  readonly value: Filter;
}

export function createFilterResultItem(filter: Filter): FilterResultItem {
  return {
    type: "Filter",
    value: filter,
  };
}

export interface Filter {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly pressureDropCurve: Curve | undefined;
}

export interface OtherResultItem {
  readonly type: "Other";
  readonly value: Other;
}

export function createOtherResultItem(other: Other): OtherResultItem {
  return {
    type: "Other",
    value: other,
  };
}

export interface Other {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressureDrop: Amount.Amount<Quantity.Pressure>;
  readonly pressureDropCurve: Curve;
}
